import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useContext } from 'react'
import { AuthContext } from '@/context/AuthContext'
import GoogleLogo from '@/assets/img/google-logo.png'

export function GoogleLoginButton() {
    const { t } = useTranslation()
    const { googleLogin, pending, setPending } = useContext(AuthContext)

    const handleGoogleLogin = () => {
        setPending(true)
        googleLogin()
    }

    return (
        <button
            className="text-medium rounded-lg shadow py-2 px-6 bg-white hover:bg-gray-100 border border-gray-300 active:ring ring-blue-100 transition-all duration-300 flex flex-row gap-1 items-center"
            onClick={handleGoogleLogin}
        >
            {pending ? (
                <FontAwesomeIcon
                    icon={faSpinner}
                    className="animate-spin mr-2"
                />
            ) : (
                <img src={GoogleLogo} alt="" className="h-8" />
            )}
            {t('Σύνδεση με Google')}
        </button>
    )
}
