/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Vehicle } from '../models/Vehicle';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class VehiclesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any Default vehicle set successfully
     * @throws ApiError
     */
    public vehiclesSetDefaultVehicleUpdate({
defaultVehicle,
}: {
defaultVehicle: number,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/vehicles/set-default-vehicle/',
            query: {
                'default_vehicle': defaultVehicle,
            },
            errors: {
                400: `Bad request`,
            },
        });
    }

    /**
     * @returns Vehicle 
     * @throws ApiError
     */
    public vehiclesVehiclesList(): CancelablePromise<Array<Vehicle>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/vehicles/vehicles/',
        });
    }

    /**
     * @returns Vehicle 
     * @throws ApiError
     */
    public vehiclesVehiclesCreate({
data,
}: {
data: Vehicle,
}): CancelablePromise<Vehicle> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/vehicles/vehicles/',
            body: data,
        });
    }

    /**
     * @returns Vehicle 
     * @throws ApiError
     */
    public vehiclesVehiclesRead({
id,
}: {
/**
 * A unique integer value identifying this Ηλεκτρικό Όχημα.
 */
id: number,
}): CancelablePromise<Vehicle> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/vehicles/vehicles/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Vehicle 
     * @throws ApiError
     */
    public vehiclesVehiclesUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this Ηλεκτρικό Όχημα.
 */
id: number,
data: Vehicle,
}): CancelablePromise<Vehicle> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/vehicles/vehicles/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns Vehicle 
     * @throws ApiError
     */
    public vehiclesVehiclesPartialUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this Ηλεκτρικό Όχημα.
 */
id: number,
data: Vehicle,
}): CancelablePromise<Vehicle> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/vehicles/vehicles/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public vehiclesVehiclesDelete({
id,
}: {
/**
 * A unique integer value identifying this Ηλεκτρικό Όχημα.
 */
id: number,
}): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/vehicles/vehicles/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
