import { useTranslation } from 'react-i18next'

import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { AuthContext } from '@/context/AuthContext'

function Disclaimer() {
    const { t, i18n } = useTranslation()
    const language = i18n.language
    const { AuthenticatedAPI } = useContext(AuthContext)

    const { data, isLoading } = useQuery({
        queryKey: [`disclaimer_content_${language}`],
        queryFn: () =>
            AuthenticatedAPI.chargingCost.chargingCostApplicationSettingsRead({
                name: `disclaimer_content_${language}`,
            }),
        enabled: true,
        retry: 3,
    })

    return (
        <div className="prose text-black">
            {isLoading ? <div>Loading...</div> : null}
            {data ? (
                <div
                    dangerouslySetInnerHTML={{
                        __html: data?.value,
                    }}
                />
            ) : null}
        </div>
    )
}

export { Disclaimer }
