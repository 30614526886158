import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChargingStation,
    faRoad,
    faBoltLightning,
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

/**
 *
 * Represents a single item (a location returned by the search)
 * displayed in the dashboard
 *
 */
export function ResultsListItem({ location, isSelected, setSelected }) {
    const { t } = useTranslation()
    if (!location) return null

    return (
        <div
            className={`relative border border-base-200 active:ring ring-sky-100 cursor-pointer bg-base-100 hover:bg-base-200 hover:bg-opacity-60 shadow rounded-xl p-2 w-full max-h-max ${
                isSelected && 'ring ring-primary'
            }`}
            key={location.id}
            onClick={() => setSelected(location)}
        >
            {/* Added a label with absolute positioning */}

            <div className="flex space-x-4">
                {location.properties.company_img ? (
                    <div className="rounded-full mx-0 my-auto w-[60px] ">
                        <img
                            src={`https://electrokinisi.yme.gov.gr/public/images/${location.properties.company_img}`}
                            alt=""
                            width="60"
                        />
                    </div>
                ) : null}

                <div className="flex flex-col w-full ">
                    <div className="flex-1 pb-2">
                        <div className="flex flex-row justify-between">
                            <div className="font-bold mt-3">
                                {location.properties.name}
                            </div>

                            <div className="flex flex-row items-start gap-2 ml-auto">
                                {location.properties.has_fast_charger && (
                                    <div
                                        className="text-xs text-yellow-600 font-medium py-1 tooltip tooltip-left"
                                        data-tip={`Υπάρχουν Ταχυφορτιστές (>50kW)`}
                                    >
                                        <FontAwesomeIcon
                                            icon={faBoltLightning}
                                            className="ml-2"
                                        />
                                    </div>
                                )}
                                <div
                                    className=" bg-gray-300 text-xs text-gray-500 font-medium opacity-80  px-2 py-1 rounded-lg tooltip tooltip-left"
                                    data-tip={`${location.properties.evse_count} Φορτιστές`}
                                >
                                    x{location.properties.evse_count}{' '}
                                    <FontAwesomeIcon
                                        icon={faChargingStation}
                                        className=" "
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="leading-none md:leading-snug">
                            {location.properties.capabilities?.length > 0
                                ? location.properties.capabilities.map(
                                      (capability, index) => (
                                          <span
                                              key={index}
                                              className="badge badge-xs  mx-1 font-medium truncate"
                                          >
                                              ✓ {t(capability)}
                                          </span>
                                      )
                                  )
                                : null}
                        </div>
                    </div>

                    <div className="flex flex-row items-baseline gap-2">
                        {location.properties.distanceFromUser ? (
                            <>
                                <div
                                    className="text-gray-400 font-bold text-sm tooltip tooltip-right"
                                    data-tip="Οι αποστάσεις αναφέρονται σε ευθεία, και μπορεί να αποκλίνουν αρκετά από την απόσταση κατά μήκος του οδικού δικτύου"
                                >
                                    <FontAwesomeIcon icon={faRoad} />{' '}
                                    {location.properties.distanceFromUser} Km
                                </div>
                            </>
                        ) : null}
                        <div className="text-secondary font-bold ml-auto">
                            <span className="text-xs font-normal ">
                                {t('normalized_cost')}{' '}
                            </span>
                            {location?.properties?.charging_cost
                                ? location?.properties?.charging_cost
                                : ' - '}{' '}
                            €/kWh
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
