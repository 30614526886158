import React, { useContext, useState, useEffect } from 'react'
import { AuthContext } from '@/context/AuthContext'
import RouteBase from '@/routes/RouteBase'
import LogoImg from '@/assets/logo.webp'
import { useNavigate, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCheckCircle,
    faExclamationCircle,
    faSpinner,
} from '@fortawesome/free-solid-svg-icons'

const ResendVerificationEmail = () => {
    const { AuthenticatedAPI, error, setError } = useContext(AuthContext)
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)

    const { email } = useLocation().state

    useEffect(() => {
        return () => {
            setError(null)
        }
    }, [])

    const requestEmailResend = (data) => {
        const { email } = data
        setLoading(true)
        setSuccess(false)
        AuthenticatedAPI.auth
            .authRegistrationResendEmailCreate({
                data: {
                    email,
                },
            })
            .then((response) => {
                setSuccess(true)
                setLoading(false)
            })
            .catch((err) => {
                console.log('error', err.message)
                setError(err.message)
                setLoading(false)
            })
    }

    useEffect(() => {
        requestEmailResend({ email: email })
    }, [])

    const LoadingComponent = () => {
        return (
            <p className="m-auto text-lg">
                <FontAwesomeIcon
                    icon={faSpinner}
                    className="animate-spin mr-2 font-bold  "
                />
                <span className="font-bold text-gray-500">
                    Αποστολή αιτήματος
                </span>
            </p>
        )
    }

    const SuccessMessageComponent = () => {
        return (
            <>
                {success && (
                    <>
                        <div className="flex flex-row items-center m-auto text-sm text-green-700 font-bold">
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                className=" mr-3"
                            />
                            <span>Το μήνυμα έχει σταλεί επιτυχώς</span>
                        </div>
                        <p className="text-sm w-64 font-medium">
                            Σας έχει σταλεί εκ νέου email με οδηγίες για την
                            επιβεβαίωση του λογαριασμού σας. Παρακαλούμε ελέγξτε
                            και το φάκελο Ανεπιθύμητης Αλληλογραφίας (Spam).
                        </p>
                        <p className="text-sm mt-4">
                            <span
                                className="link text-blue-900"
                                onClick={() => {
                                    requestEmailResend({ email: email })
                                }}
                            >
                                Αποστολή ξανά
                            </span>
                        </p>
                    </>
                )}
            </>
        )
    }

    const ErrorMessageComponent = () => {
        return (
            <>
                <div className="flex flex-row items-center  text-red-800 font-bold">
                    <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className=" mr-3"
                    />
                    <span>Σφάλμα</span>
                </div>

                {error ? (
                    <p className="text-xs bg-red-100 text-red-800 rounded-md px-2 py-1 font-medium my-2">
                        {error}
                    </p>
                ) : null}

                <p className="text-sm w-64 font-medium">
                    Προέκυψε κάποιο σφάλμα κατά την αποστολή του μηνύματος.
                </p>
                <p className="text-sm mt-4">
                    <span
                        className="link text-blue-900"
                        onClick={() => {
                            requestEmailResend({ email: email })
                        }}
                    >
                        Προσπαθήστε ξανά
                    </span>
                </p>
            </>
        )
    }

    return (
        <RouteBase classNames="flex flex-col content-center items-center h-full">
            <div className="h-full w-full flex flex-col">
                <div className="m-auto rounded-lg bg-blue-100 bg-opacity-20 border border-blue-100 p-4 flex  flex-col gap-3 ">
                    <div className="h-content flex flex-col md:flex-row ">
                        <img
                            src={LogoImg}
                            alt=""
                            width="150"
                            className="m-auto"
                        />

                        <div className="divider lg:divider-horizontal"></div>

                        <div className="flex flex-col p-5 px-7">
                            {loading ? (
                                <LoadingComponent />
                            ) : success ? (
                                <SuccessMessageComponent />
                            ) : (
                                <ErrorMessageComponent />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </RouteBase>
    )
}

export default ResendVerificationEmail
