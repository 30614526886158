function mapsSelector() {
    if (
        /* if we're on iOS, open in Apple Maps */
        navigator.platform.indexOf('iPhone') != -1 ||
        navigator.platform.indexOf('iPad') != -1 ||
        navigator.platform.indexOf('iPod') != -1
    )
        return 'maps://maps.google.com/maps'
    /* else use Google */ else return 'https://maps.google.com/maps'
}

export function openGmaps(userPosition, [lng, lat, h]) {
    let baseurl = mapsSelector()
    if (!baseurl) baseurl = 'https://www.google.com/maps'

    if (userPosition && userPosition.latitude && userPosition.longitude) {
        const url = `${baseurl}/dir/?api=1&origin=${userPosition.latitude},${userPosition.longitude}&destination=${lat},${lng}`
        window.open(url, '_blank')
    } else {
        const url = `${baseurl}/maps/search/?api=1&query=${lat},${lng}`
        window.open(url, '_blank')
    }
}
