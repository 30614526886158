import React from 'react'
import { useTranslation } from 'react-i18next'
import { DataContext } from '@/context/DataContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBug, faXmark } from '@fortawesome/free-solid-svg-icons'

const LocationDetailsError = () => {
    const { t } = useTranslation()
    const { selectedLocation, setSelectedLocation } =
        React.useContext(DataContext)

    const CloseButton = () => {
        return (
            <button
                className="btn btn-xs btn-outline btn-circle ml-2"
                onClick={() => {
                    setSelectedLocation(null)
                }}
            >
                <FontAwesomeIcon icon={faXmark} />
            </button>
        )
    }
    return (
        <div className="p-4 w-full h-full text-xs flex flex-col bg-gray-200 rounded-lg">
            <div className="flex flex-col w-full h-full border-base-200 pb-1 text-lg">
                <div className="flex flex-row items-center content-center ">
                    <h2 className="rounded-lg bg-gray-300 h-10 w-full "></h2>
                    <div className="flex flex-row ml-auto items-center">
                        <CloseButton />
                    </div>
                </div>
                <div className="rounded-lg bg-gray-300 h-24 w-full mt-2"></div>
                <div className="flex flex-col items-center rounded-lg bg-gray-300 h-full w-full mt-2">
                    <h2 className="m-auto font-bold text-gray-700">
                        <FontAwesomeIcon icon={faBug} className="mr-2" />
                        {t('Προέκυψε κάποιο σφάλμα')}
                    </h2>
                </div>
            </div>
        </div>
    )
}

export { LocationDetailsError }
