import { ParameterWalkthrough } from '@/components/dashboard/ParameterWalkthrough'
import { AuthContext } from '@/context/AuthContext'
import { DataContext } from '@/context/DataContext'
import { Navigate } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import { useUserPosition } from '@/modules/position/context'

function InitialSearchWizard() {
    const { user } = useContext(AuthContext)
    const { initialPageLoad } = useContext(DataContext)
    const { startTracking } = useUserPosition()

    useEffect(() => {
        if (user?.isAuthenticated || !initialPageLoad) return
        startTracking()
    }, [startTracking])

    if (user?.isAuthenticated || !initialPageLoad)
        // return <ParameterWalkthrough />
        return <Navigate to="/dashboard" />

    return <ParameterWalkthrough />
}

export default InitialSearchWizard
