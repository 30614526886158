import React, { useContext, useState, useEffect } from 'react'
import { AuthContext } from '@/context/AuthContext'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import RouteBase from '@/routes/RouteBase'
import LogoImg from '@/assets/logo.webp'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { GoogleLoginButton } from '@/components/accounts/GoogleLoginButton'

const Signup = () => {
    const { t } = useTranslation()
    const { AuthenticatedAPI, error, setError } = useContext(AuthContext)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const {
        handleSubmit,
        register,
        formState: { errors: FormErrors },
    } = useForm()

    const ApiErrors = error

    console.log('ApiErrors', ApiErrors)
    console.log('FormErrors', FormErrors)

    // Clear error on unmount
    useEffect(() => {
        return () => {
            setError(null)
        }
    }, [])

    const handleSignup = (data) => {
        const { username, email, password1, password2 } = data

        setLoading(true)
        AuthenticatedAPI.auth
            .authRegistrationCreate({
                data: {
                    username,
                    email,
                    password1,
                    password2,
                },
            })
            // Data is empty, expecting a 204 (Empty content, succes)
            .then((response) => {
                setLoading(false)
                navigate('/login', {
                    replace: true,
                    state: {
                        message: response.detail,
                        success: true,
                        newSignup: true,
                        signupEmail: email,
                    },
                })
            })
            .catch((err) => {
                console.log('error', err.body)
                setError(err.body)
                setLoading(false)
            })
    }

    const SubmitButtonComponent = () => {
        return (
            <button type="submit" className="mt-4 btn btn-outline mr-auto">
                {loading && (
                    <FontAwesomeIcon
                        icon={faSpinner}
                        className="animate-spin mr-2"
                    />
                )}
                Εγγραφή
            </button>
        )
    }

    const FormComponent = () => {
        return (
            <form
                onSubmit={handleSubmit(handleSignup)}
                className="flex flex-col p-5 px-7 "
            >
                <h1 className=" text-xl  my-4">Δημιουργία Λογαριασμού </h1>

                <div className="form-group mb-5 font-medium">
                    <label htmlFor="username">Όνομα χρήστη</label>
                    <input
                        {...register('username', {
                            απαιτείται: 'Αυτό το πεδίο είναι υποχρεωτικό.',
                        })}
                        type="text"
                        placeholder="user12345"
                        className="w-full input input-bordered input-primary"
                        id="username"
                        name="username"
                    />
                    {FormErrors.username && (
                        <small className="text-error">
                            {FormErrors.username[0]}
                        </small>
                    )}
                    {ApiErrors?.username && (
                        <small className="text-error">
                            {ApiErrors.username[0]}
                        </small>
                    )}
                </div>
                <div className="form-group mb-5 font-medium">
                    <label htmlFor="email">Διεύθυνση e-mail</label>
                    <input
                        {...register('email', {
                            απαιτείται: 'Αυτό το πεδίο είναι υποχρεωτικό.',
                        })}
                        type="text"
                        placeholder="user@email.com"
                        className="w-full input input-bordered input-primary"
                        id="email"
                        name="email"
                    />
                    {FormErrors.email && (
                        <small className="text-error">
                            {FormErrors.email[0]}
                        </small>
                    )}
                    {ApiErrors?.email && (
                        <small className="text-error">
                            {ApiErrors.email[0]}
                        </small>
                    )}
                </div>
                <div className="form-group mb-5 font-medium">
                    <label htmlFor="password1">Κωδικός Πρόσβασης</label>
                    <input
                        {...register('password1', {
                            απαιτείται: 'Αυτό το πεδίο είναι υποχρεωτικό.',
                        })}
                        type="password"
                        placeholder="*****"
                        className="w-full input input-bordered input-primary"
                        id="password1"
                        name="password1"
                    />
                    {FormErrors.password1 && (
                        <small className="text-error">
                            {FormErrors.password1[0]}
                        </small>
                    )}
                    {ApiErrors?.password1 && (
                        <small className="text-error">
                            {ApiErrors.password1[0]}
                        </small>
                    )}
                </div>
                <div className="form-group mb-5 font-medium">
                    <label htmlFor="password2">
                        Κωδικός Πρόσβασης (Επιβεβαίωση)
                    </label>
                    <input
                        {...register('password2', {
                            απαιτείται: 'Αυτό το πεδίο είναι υποχρεωτικό.',
                        })}
                        type="password"
                        placeholder="*****"
                        className="w-full input input-bordered input-primary"
                        id="password2"
                        name="password2"
                    />
                    {FormErrors.password2 && (
                        <small className="text-error">
                            {FormErrors.password2[0]}
                        </small>
                    )}
                    {ApiErrors?.password2 && (
                        <small className="text-error">
                            {ApiErrors.password2[0]}
                        </small>
                    )}
                </div>

                {/* Add any additional fields for the signup form here */}

                {ApiErrors?.non_field_errors &&
                    ApiErrors.non_field_errors.map((err, index) => {
                        return (
                            <small className="text-error" key={index}>
                                {err}
                            </small>
                        )
                    })}

                <SubmitButtonComponent />
            </form>
        )
    }

    return (
        <RouteBase classNames="flex flex-col content-center items-center h-full">
            <div className="h-full w-full flex flex-col">
                <div className="m-auto rounded-lg bg-blue-100 bg-opacity-20 border border-blue-100 p-4 flex  flex-col gap-3 ">
                    <div className="h-content flex flex-col gap-10 md:flex-row ">
                        <img
                            src={LogoImg}
                            alt=""
                            width="150"
                            className="m-auto"
                        />

                        <FormComponent />
                    </div>
                    <div className="divider">{t('or')}</div>
                    <div className="flex justify-center ">
                        <GoogleLoginButton />
                    </div>
                </div>
            </div>
        </RouteBase>
    )
}

export default Signup
