import React, { useEffect, useState, useContext } from 'react'
import { DataContext } from '@/context/DataContext'
import { useTranslation } from 'react-i18next'
import Select from 'react-tailwindcss-select'
import Modal from '@/components/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faInfoCircle,
    faSquareArrowUpRight,
    faX,
    faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { CircleButton } from '@/components/buttons/CircleButton'

function CapabilitiesDetails({ choices, showDetails, setShowDetails }) {
    const { t, i18n } = useTranslation()

    const lang = i18n.language

    const hide = () => {
        setShowDetails(false)
    }

    const data = {
        categories: choices.map((choice) => {
            return {
                title: choice[`label_${lang}`],
                description: choice[`description_${lang}`],
            }
        }),
    }

    return (
        <Modal
            isOpen={showDetails}
            onClose={hide}
            contentLabel={t('Δυνατότητες Φορτιστή')}
        >
            <div className="md:bg-white md:border md:w-[60vw] min-h-[40vh] md:m-auto p-5 rounded-lg relative">
                {/* Header */}
                <div className="bg-white absolute top-0 left-0 right-0 p-3 flex flex-row w-full items-center rounded-t-lg pb-2 z-20 shadow ">
                    <h3 className="font-bold">{t('Δυνατότητες Φορτιστή')}</h3>
                    <CircleButton
                        // className="btn btn-outline btn-circle ml-auto"
                        onClick={hide}
                        icon={faXmark}
                    />
                </div>

                <div className="overflow-y-auto max-h-[80vh] pt-14 ">
                    <table className="table table-compact table-zebra">
                        <tbody>
                            {data.categories?.map((category, index) => {
                                return (
                                    <tr key={index}>
                                        <th className="bg-transparent">
                                            <h3 className="a">
                                                {category.title}
                                            </h3>
                                        </th>
                                        <td className="bg-transparent">
                                            <p className="font-medium">
                                                {category.description}
                                            </p>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </Modal>
    )
}

function CapabilitiesExplain({ choices }) {
    const { t } = useTranslation()
    const [showDetails, setShowDetails] = useState(false)

    return (
        <>
            <div className="dropdown z-50">
                <label
                    tabIndex={0}
                    className="btn btn-circle btn-ghost btn-xs text-info "
                    aria-label={t('Δυνατότητες Φορτιστή')}
                >
                    <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="text-info"
                    />
                </label>
                <div
                    tabIndex={0}
                    className="card compact dropdown-content shadow bg-base-100 rounded-box w-64"
                >
                    <div className="card-body">
                        <label className="card-title">
                            {t('Δυνατότητες Φορτιστή')}
                        </label>
                        <p>
                            {t(
                                'Επιλέγοντας φίλτρα για τις δυνατότητες του φορτιστή, κάθε σταθμός που επιστρέφεται στα αποτελέσματα διαθέτει τουλάχιστον ένα φορτιστή με όλα τα επιλεγμένα φίλτρα.'
                            )}
                        </p>
                        <div
                            className="link"
                            onClick={() => {
                                setShowDetails(true)
                            }}
                        >
                            {t('Τι σημαίνουν οι αναφερόμενες δυνατότητες;')}
                        </div>
                    </div>
                </div>
            </div>
            <CapabilitiesDetails
                choices={choices}
                showDetails={showDetails}
                setShowDetails={setShowDetails}
            />
        </>
    )
}

function CapabilitiesFilter({
    title,
    tooltip,
    parameter,
    choices,
    setParam,
    size = 'sm',
    onSidebar = false,
    className,
    ...rest
}) {
    const { t, i18n } = useTranslation()
    const data = useContext(DataContext)
    const parameterValue = data.params[parameter] // global state
    const [selected, setSelected] = useState(null) // local state
    const [modalOpen, setModalOpen] = useState(false)

    const lang = i18n.language

    useEffect(() => {
        if (parameterValue) {
            const selectedValues = parameterValue.split(', ')
            const selectedOptions = choices
                .filter((choice) => selectedValues.includes(choice.name))
                .map((choice) => {
                    return {
                        value: choice.name,
                        label: choice[`label_${lang}`],
                    }
                })
            setSelected(selectedOptions)
        } else {
            setSelected(null)
        }
    }, [parameterValue])

    const handleChange = (value) => {
        if (!value || value.length === 0) {
            setParam(parameter, null)
        } else {
            setParam(parameter, value.map((s) => s.value).join(', '))
        }
    }

    const options =
        choices?.map((choice, index) => {
            return {
                value: choice.name,
                label: choice[`label_${lang}`],
            }
        }) || []

    const container = (children) => {
        if (onSidebar === true) {
            return (
                <>
                    <Modal
                        isOpen={modalOpen}
                        contentLabel={t('Δυνατότητες Φορτιστή')}
                    >
                        <div className="bg-white border md:w-[60vw] min-h-[40vh] m-auto p-5 rounded-lg">
                            <div className="flex flex-row w-full">
                                <h3 className="font-bold">
                                    {t('Δυνατότητες Φορτιστή')}
                                </h3>
                                <button
                                    className="btn btn-ghost btn-circle ml-auto"
                                    onClick={(e) => {
                                        setModalOpen(false)
                                    }}
                                >
                                    <FontAwesomeIcon icon={faX} />
                                </button>
                            </div>
                            {children}
                        </div>
                    </Modal>
                    <button
                        className={`btn btn-${size} mt-2`}
                        onClick={(e) => {
                            setModalOpen(true)
                        }}
                    >
                        {t('Δυνατότητες Φορτιστή')} &nbsp;
                        <FontAwesomeIcon icon={faSquareArrowUpRight} />
                    </button>
                </>
            )
        } else {
            return <>{children}</>
        }
    }

    const content = () => {
        return (
            <>
                <div
                    className={
                        'flex flex-col text-left content-center items-start rounded-lg p-2 px-0 cursor-pointer ' +
                        className
                    }
                >
                    <label className="text-left" for="capabilities-filter">
                        &nbsp; {t(title)} &nbsp;
                        <CapabilitiesExplain choices={choices} />
                    </label>
                    <div
                        className={`${
                            !onSidebar
                                ? 'tooltip tooltip-top md:tooltip-right'
                                : ''
                        } flex flex-row w-full`}
                        data-tip={!onSidebar ? t(tooltip) : null}
                    >
                        <Select
                            id="capabilities-filter"
                            className={`select select-${size} w-48 text-black`}
                            value={selected}
                            size={size}
                            isMultiple={true}
                            onChange={handleChange}
                            options={options}
                            isClearable={true}
                            placeholder={t('filters.select_capabilities')}
                            classNames={{
                                menuButton: (isDisabled) =>
                                    `border border-base-content border-opacity-20 bg-base-100 rounded-btn flex flex-row h-content transition-all duratin-200 focus:ring ring-secondary break-words${
                                        onSidebar ? 'w-48' : 'w-full'
                                    }`,
                                menu: `absolute left-0 right-0 bg-white rounded-lg shadow`,
                                tagItem: (item, isDisabled) =>
                                    'bg-base-200 rounded-lg px-2 flex flex-row break-word',
                                tagItemText: `break-normal text-${size} text-black`,
                                list: `dropdown-content rounded-lg `,
                                listItem: (isSelected) =>
                                    `block text-left py-1 px-4 rounded-lg hover:bg-gray-700 hover:text-white active:ring ring-blue-100 w-full break-normal text-left m-1 transition-all duration-200`,
                                ChevronIcon: 'hidden',
                            }}
                            {...rest}
                        ></Select>
                    </div>
                </div>
            </>
        )
    }

    return container(content())
}

export { CapabilitiesFilter }
