import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Disclaimer } from '@/components/Disclaimer'

function DisclaimerPage() {
    const { t } = useTranslation()

    return (
        <div className="min-h-screen pb-[64px] md:pt-[64px] md:pb-10 flex flex-col justify-center mx-4">
            <main className="h-full flex flex-col justify-center gap-10 ">
                <section className="lg:max-w-7xl w-full  py-4">
                    <div className="container px-3 md:pl-6 prose mx-auto text-black text-lg">
                        <Link to="/" className="link text-blue-800" link>
                            <FontAwesomeIcon
                                icon={faChevronLeft}
                                className="mr-2"
                            />
                            {t('Πίσω')}
                        </Link>
                        <h2 className="text-4xl font-bold mb-4">
                            {t('disclaimer.title')}
                        </h2>

                        <Disclaimer />
                    </div>
                </section>
            </main>
        </div>
    )
}

export default DisclaimerPage
