import React, { createContext, useState, useEffect } from 'react'

const AnalyticsContext = createContext()

// MUST be prefixed with VITE_
// see: https://vitejs.dev/guide/env-variables.html
const ANALYTICS_ID = import.meta.env.VITE_GOOGLE_ANALYTICS_ID

const AnalyticsProvider = ({ children }) => {
    const [consent, setConsent] = useState(false)

    const initializeGA4 = () => {
        if (!consent || !ANALYTICS_ID) return

        import('react-ga4').then((rga) => {
            const ReactGA = rga.default
            ReactGA.initialize(ANALYTICS_ID, {
                testMode: import.meta.env.DEV,
                gaOptions: {
                    anonymizeIp: true,
                    allowAdFeatures: true,
                    allowAdPersonalizationSignals: true,
                },
            })
        })

        import.meta.env.DEV &&
            console.info('Initializing GA4 with ID:', ANALYTICS_ID)
    }

    const sendPageView = ({ page, title }) => {
        if (!consent || !ANALYTICS_ID) return

        import('react-ga4').then((rga) => {
            const ReactGA = rga.default
            ReactGA.send({ hitType: 'pageview', page: page, title: title })

            import.meta.env.DEV &&
                console.info(`GA4 pageView: ${page}, title: ${title}`)
        })
    }

    useEffect(() => {
        initializeGA4()
    }, [consent])

    const value = { sendPageView, setConsent }

    return (
        <AnalyticsContext.Provider value={value}>
            {import.meta.env.DEV && (
                <div className="fixed top-0 left-1/2 -translate-x-1/2 z-50 bg-opacity-80 bg-blue-300 rounded-lg font-bold p-2 opacity-30 hover:opacity-100 text-xs">
                    <p>{consent ? 'Analytics accepted' : 'Analytics denied'}</p>
                    <p>
                        {ANALYTICS_ID
                            ? `ID: ${ANALYTICS_ID}`
                            : 'No Analytics ID provided'}
                    </p>
                </div>
            )}
            {children}
        </AnalyticsContext.Provider>
    )
}

// useAnalytics hook
const useAnalytics = () => {
    const context = React.useContext(AnalyticsContext)
    if (context === undefined) {
        throw new Error('useAnalytics must be used within a AnalyticsProvider')
    }
    return context
}

export { AnalyticsProvider, useAnalytics }
