import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { AuthContext } from '@/context/AuthContext'
import { useQuery } from '@tanstack/react-query'

const EmptySearchPlaceholder = () => {
    const { i18n } = useTranslation()

    const language = i18n.language
    const { AuthenticatedAPI } = useContext(AuthContext)

    const { data: empty_search_text, isLoading } = useQuery({
        queryKey: [`empty_search_content_${language}`],
        queryFn: () =>
            AuthenticatedAPI.chargingCost.chargingCostApplicationSettingsRead({
                name: `empty_search_content_${language}`,
            }),
        enabled: true,
    })

    return (
        <div className="w-full h-full flex rounded-lg  shadow-inner min-h-max py-14 overflow-y-auto">
            {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : null}
            {empty_search_text ? (
                <div
                    dangerouslySetInnerHTML={{
                        __html: empty_search_text.value,
                    }}
                />
            ) : null}
        </div>
    )
}

export { EmptySearchPlaceholder }
