import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function CircleButton({ tooltip, icon, onClick, className, ...rest }) {
    const classes = `btn-ghost btn-circle btn-sm mr-1 flex items-center justify-center ml-auto active:bg-gray-400 border-gray-200 focus:border-primary transition-all tooltip tooltip-left  ${className}`
    return (
        <button
            className={classes}
            data-tip={tooltip}
            onClick={onClick}
            {...rest}
        >
            <FontAwesomeIcon icon={icon} className="font-medium text-center " />
        </button>
    )
}

export { CircleButton }
