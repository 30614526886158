const getUniqueOperators = (data) =>
    data?.features
        ?.reduce((uniqueOperators, location) => {
            const operator = location.properties.operator
            if (operator && !uniqueOperators.includes(operator)) {
                uniqueOperators.push(operator)
            }
            return uniqueOperators
        }, [])
        .sort(
            (a, b) => a.localeCompare(b || '') // sort alphabetically
        ) || []

const filterData = ({ data, selectedOperator, searchText, showNullCost }) => {
    if (!data) return null
    return (
        data?.features?.filter((location) => {
            const searchTextMatch =
                location.properties.name +
                location.properties.operator +
                location.properties.location_id

            return (
                (searchTextMatch
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                    location.properties.operator
                        .toLowerCase()
                        .includes(searchText.toLowerCase())) &&
                (selectedOperator === 'All' ||
                    location.properties.operator
                        .toLowerCase()
                        .includes(selectedOperator.toLowerCase())) &&
                (showNullCost
                    ? true
                    : location.properties.charging_cost !== null)
            )
        }) || []
    )
}

export { getUniqueOperators, filterData }
