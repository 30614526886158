import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'

import { useUserPositionStore } from '@/modules/position/context'
import { useShallow } from 'zustand/react/shallow'
import { SearchNameInput } from '@/components/dashboard/SearchName'

import { SortingOption } from '@/lib/sorting'

export function ResultsToolbar({
    data,
    sortedData,
    filters,
    setFilters,
    sortingOption,
    setSortingOption,
}) {
    const { t } = useTranslation()
    const userPosition = useUserPositionStore(
        useShallow((state) => state.userPosition)
    )
    const { searchText } = filters
    const setSearchText = (text) => setFilters({ ...filters, searchText: text })

    const totalLocations = data?.features ? data.features.length : 0
    const visibleLocations = data ? sortedData.length : null

    const [firstUserPosition, setFirstUserPosition] = useState(true)

    const handleSortingChange = (e) => {
        setSortingOption(e.target.value)
    }

    // Options, filter distance if userPosition is available
    const options = Object.entries(SortingOption).filter(([key]) => {
        if (!userPosition) {
            return ![
                'DISTANCE_ASCENDING',
                'DISTANCE_DESCENDING',
                'PRICE_DIST_ASCENDING',
            ].includes(key)
        }
        return true
    })

    // If userPosition becomes available, change sorting option
    useEffect(() => {
        if (!userPosition || !firstUserPosition) return

        setSortingOption(SortingOption.PRICE_DIST_ASCENDING)
        setFirstUserPosition(false)
    }, [userPosition])

    return (
        <>
            <div className="flex flex-col md:flex-row  gap-2 justify-between w-full content-center px-3 pt-3">
                <div className="text-sm text-secondary font-bold ">
                    {t('Βρέθηκαν')} {sortedData ? sortedData.length : '-'}{' '}
                    {t('σταθμοί')}
                    {totalLocations !== visibleLocations ? (
                        <span className="text-gray-400">
                            {' '}
                            ({totalLocations - visibleLocations}{' '}
                            {t('κρυμμένοι')})
                        </span>
                    ) : null}
                </div>
                <div className="justify-start flex flex-row gap-1 items-center ">
                    <FontAwesomeIcon icon={faSort} className=" text-gray-400" />
                    <select
                        value={sortingOption}
                        onChange={handleSortingChange}
                        className="select select-bordered w-auto select-xs "
                    >
                        {
                            // Map over options
                            options.map(([key, value]) => (
                                <option key={key} value={value}>
                                    {t(value)}
                                </option>
                            ))
                        }
                    </select>
                </div>
            </div>

            <div className="w-full flex flex-col gap-1 pb-1 items-start justify-center  px-3">
                {/* Search location name */}
                <SearchNameInput onChange={setSearchText} value={searchText} />
            </div>
        </>
    )
}
