import React, { useContext } from 'react'
import { AuthContext } from '@/context/AuthContext'
import { useLocation } from 'react-router-dom'
import NotFound from '@/routes/error/NotFound'
import { LoadingScreen } from '@/components/LoadingScreen'

import { IUser } from '../types/User'

type TRole =
    | 'is_superuser'
    | 'is_staff'
    | 'is_rae_admin'
    | 'is_operator_admin'
    | 'is_operator_staff'

function enforce(user: IUser, roles: TRole[]) {
    let allow = false
    roles.forEach((role) => {
        const hasRole = user[role] !== undefined ? user[role] : false
        if (hasRole) {
            allow = true
        }
    })
    return allow
}

const StaffProtectedRoute = ({
    children,
    roles,
}: {
    children: React.ReactNode
    roles: TRole[]
}) => {
    const { user, pending } = useContext(AuthContext)
    let location = useLocation()

    // Check if the user object is not available yet (pending)
    if (pending || !user || !user.isAuthenticated || !user?.profile) {
        return <LoadingScreen />
    }

    // Check if the user is authenticated and enforce the roles
    if (user?.isAuthenticated && user?.profile && enforce(user, roles)) {
        return children
    } else {
        return <NotFound />
        //return <Navigate to="/not-found" state={{ from: location }} replace />;
    }
}

export default StaffProtectedRoute
