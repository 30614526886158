import React from 'react'
import { useLocation } from 'react-router-dom'
import { useAnalytics } from './analytics-context'

/**
 * Wraps all Routes in react-router and sends page-views to analytics
 * The component should be a direct child of Router and parent of Routes
 */
const AnalyticsHandler = ({ children }) => {
    const location = useLocation()
    const { sendPageView } = useAnalytics()

    // Ignore hash
    const currentPage = location.pathname + location.search

    const getTitle = (path) => {
        if (path === '/') {
            return 'Home'
        }

        return 'Other'
    }

    React.useEffect(() => {
        sendPageView({
            page: currentPage,
            title: getTitle(currentPage) || document.title,
        })
        // console.log("Page view sent with title:", getTitle(currentPage));
    }, [currentPage, sendPageView])

    return <React.Fragment>{children}</React.Fragment>
}

export default AnalyticsHandler
