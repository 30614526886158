import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { AuthProvider } from '@/context/AuthContext'
import { DataProvider } from '@/context/DataContext'
import { MapProvider } from '@/context/MapContext'
import { RoutingProvider } from '@/modules/routing/context'
import { GoogleOAuthProvider } from '@react-oauth/google'

// analytics
import { AnalyticsProvider } from '@/modules/analytics/analytics/analytics-context'
import AnalyticsHandler from '@/modules/analytics/analytics/analytics-handler'

// cookies
import AppCookieConsent from '@/modules/analytics/cookies/CookieConsent'

import Suspended from '@/components/Suspended'

import ProtectedRoute from '@/routes/_ProtectedRoute'
import StaffProtectedRoute from '@/routes/_StaffProtectedRoute'
import NotFound from '@/routes/error/NotFound'
import PageLayout from '@/layout/PageLayout'

import HomePage from '@/routes/Home'
import AboutPage from '@/routes/About'
import DisclaimerPage from '@/routes/DisclaimerPage'
import PriceDashboard from '@/routes/PriceDashboard'
import InitialSearchWizard from '@/routes/InitialSearchWizard'

import Login from '@/routes/accounts/Login'
import Signup from '@/routes/accounts/Signup'
import ForgotPassword from '@/routes/accounts/ForgotPassword'
import PasswordResetConfirm from '@/routes/accounts/PasswordResetConfirm'
import ResendVerificationEmail from '@/routes/accounts/ResendVerificationEmail'

const UserProfile = React.lazy(() => import('@/routes/accounts/UserProfile'))
const ChangePassword = React.lazy(() =>
    import('@/routes/accounts/ChangePassword')
)
const AddChargingPolicy = React.lazy(() =>
    import('@/routes/operators/AddChargingPolicy')
)
const ListChargingPolicy = React.lazy(() =>
    import('@/routes/operators/ListChargingPolicy')
)
const AddPolicyGroup = React.lazy(() =>
    import('@/routes/operators/AddPolicyGroup')
)
const ListPolicyGroup = React.lazy(() =>
    import('@/routes/operators/ListPolicyGroup')
)
const StatisticsPage = React.lazy(() => import('@/routes/admin/Statistics'))
const CompanyStatsDetails = React.lazy(() =>
    import('@/routes/admin/CompanyStatsDetails')
)

import maplibregl from 'maplibre-gl'
import { Protocol } from 'pmtiles'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

function App() {
    useEffect(() => {
        const protocol = new Protocol()
        maplibregl.addProtocol('pmtiles', protocol.tile)
        return () => {
            maplibregl.removeProtocol('pmtiles')
        }
    }, [])

    return (
        <>
            <AnalyticsProvider>
                <QueryClientProvider client={queryClient}>
                    <GoogleOAuthProvider
                        clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}
                    >
                        <AuthProvider>
                            <BrowserRouter
                                basename={
                                    import.meta.env.VITE_FRONTEND_ROOT || '/app'
                                }
                            >
                                <AnalyticsHandler>
                                    <DataProvider>
                                        <Routes>
                                            <Route
                                                path="/"
                                                element={<PageLayout />}
                                            >
                                                <Route
                                                    index
                                                    element={<HomePage />}
                                                />
                                                <Route
                                                    path="/about"
                                                    element={<AboutPage />}
                                                />
                                                <Route
                                                    path="/disclaimer"
                                                    element={<DisclaimerPage />}
                                                />
                                                <Route
                                                    path="/login"
                                                    element={<Login />}
                                                />
                                                <Route
                                                    path="/login/:extra_params"
                                                    element={<Login />}
                                                />
                                                <Route
                                                    path="/signup"
                                                    element={<Signup />}
                                                />
                                                <Route
                                                    path="/reset-password"
                                                    element={<ForgotPassword />}
                                                />
                                                <Route
                                                    path="/password-reset-confirm/:uid/:token"
                                                    element={
                                                        <PasswordResetConfirm />
                                                    }
                                                />
                                                <Route
                                                    path="/resend-verification-email"
                                                    element={
                                                        <ResendVerificationEmail />
                                                    }
                                                />
                                                <Route
                                                    path="initial-search"
                                                    element={
                                                        <InitialSearchWizard />
                                                    }
                                                />
                                                <Route
                                                    path="/dashboard"
                                                    element={
                                                        <MapProvider>
                                                            <RoutingProvider>
                                                                <PriceDashboard />
                                                            </RoutingProvider>
                                                        </MapProvider>
                                                    }
                                                    errorElement={<NotFound />}
                                                />
                                                <Route
                                                    path="/user-profile"
                                                    element={
                                                        <Suspended>
                                                            <ProtectedRoute>
                                                                <UserProfile />
                                                            </ProtectedRoute>
                                                        </Suspended>
                                                    }
                                                />
                                                <Route
                                                    path="/change-password"
                                                    element={
                                                        <Suspended>
                                                            <ProtectedRoute>
                                                                <ChangePassword />
                                                            </ProtectedRoute>
                                                        </Suspended>
                                                    }
                                                />
                                                <Route
                                                    path="/add-policy"
                                                    element={
                                                        <Suspended>
                                                            <StaffProtectedRoute
                                                                roles={[
                                                                    'is_operator_staff',
                                                                    'is_superuser',
                                                                ]}
                                                            >
                                                                <AddChargingPolicy />
                                                            </StaffProtectedRoute>
                                                        </Suspended>
                                                    }
                                                />
                                                <Route
                                                    path="/list-policy"
                                                    element={
                                                        <Suspended>
                                                            <StaffProtectedRoute
                                                                roles={[
                                                                    'is_operator_staff',
                                                                    'is_superuser',
                                                                    'is_operator_admin',
                                                                ]}
                                                            >
                                                                <ListChargingPolicy />
                                                            </StaffProtectedRoute>
                                                        </Suspended>
                                                    }
                                                />
                                                <Route
                                                    path="/add-policy-group"
                                                    element={
                                                        <Suspended>
                                                            <StaffProtectedRoute
                                                                roles={[
                                                                    'is_operator_staff',
                                                                    'is_superuser',
                                                                ]}
                                                            >
                                                                <AddPolicyGroup />
                                                            </StaffProtectedRoute>
                                                        </Suspended>
                                                    }
                                                />
                                                <Route
                                                    path="/list-policy-group"
                                                    element={
                                                        <Suspended>
                                                            <StaffProtectedRoute
                                                                roles={[
                                                                    'is_operator_staff',
                                                                    'is_superuser',
                                                                    'is_operator_admin',
                                                                ]}
                                                            >
                                                                <ListPolicyGroup />
                                                            </StaffProtectedRoute>
                                                        </Suspended>
                                                    }
                                                />
                                                <Route
                                                    path="/statistics"
                                                    element={
                                                        <Suspended>
                                                            <StaffProtectedRoute
                                                                roles={[
                                                                    'is_superuser',
                                                                    'is_rae_admin',
                                                                ]}
                                                            >
                                                                <StatisticsPage />
                                                            </StaffProtectedRoute>
                                                        </Suspended>
                                                    }
                                                />
                                                <Route
                                                    path="/statistics/:company_id/"
                                                    element={
                                                        <Suspended>
                                                            <StaffProtectedRoute
                                                                roles={[
                                                                    'is_superuser',
                                                                    'is_rae_admin',
                                                                ]}
                                                            >
                                                                <CompanyStatsDetails />
                                                            </StaffProtectedRoute>
                                                        </Suspended>
                                                    }
                                                />
                                                <Route
                                                    path="*"
                                                    element={<NotFound />}
                                                />{' '}
                                                {/* 404 */}
                                            </Route>
                                        </Routes>
                                    </DataProvider>
                                </AnalyticsHandler>
                            </BrowserRouter>
                            <AppCookieConsent />
                        </AuthProvider>
                    </GoogleOAuthProvider>
                </QueryClientProvider>
            </AnalyticsProvider>
            {/* <FooterComponent/> */}
        </>
    )
}

export default App
