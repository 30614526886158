import type { LocationPoints } from '@/api'

export enum SortingOption {
    PRICE_ASCENDING = 'Τιμή (Αύξουσα)',
    PRICE_DESCENDING = 'Τιμή (Φθίνουσα)',
    DISTANCE_ASCENDING = 'Απόσταση (Αύξουσα)',
    DISTANCE_DESCENDING = 'Απόσταση (Φθίνουσα)',
    // OPERATOR_ASCENDING = 'Πάροχος (Α-Ω)',
    // OPERATOR_DESCENDING = 'Πάροχος (Ω-Α)',
    PRICE_DIST_ASCENDING = 'Τιμή και Απόσταση (Αύξουσα)',
    // PRICE_DIST_DESCENDING = 'Τιμή και Απόσταση (Φθίνουσα)',
}

/*
 * Normalize distance from user to 0-1 scale
 **/
const normalize_distance = (locations: LocationPoints[]) => {
    const distances = locations.map(
        (location) => location.properties.distanceFromUser
    )
    const max_distance = Math.max(...distances)
    const min_distance = Math.min(...distances)
    return locations.map((location) => {
        location.properties.norm_distanceFromUser =
            (location.properties.distanceFromUser - min_distance) /
            (max_distance - min_distance)
        return location
    })
}

/*
 * Normalize cost to 0-1 scale
 **/
const normalize_cost = (locations: LocationPoints[]) => {
    const costs = locations.map((location) => location.properties.charging_cost)
    const max_cost = Math.max(...costs)
    const min_cost = Math.min(...costs)
    return locations.map((location) => {
        location.properties.norm_charging_cost =
            location.properties.charging_cost &&
            location.properties.charging_cost > 0
                ? (location.properties.charging_cost - min_cost) /
                  (max_cost - min_cost)
                : 10
        return location
    })
}

/*
 * Sort data based on sorting option
 **/
export const sortData = async (
    data: LocationPoints[],
    sortingOption: SortingOption
): Promise<LocationPoints[]> => {
    if (!data || data.length === 0) return []

    // Normalize distance and cost to 0-1 scale
    const normalized_data = normalize_distance(normalize_cost(data))

    const sorted =
        normalized_data?.sort((a, b) => {
            if (sortingOption === SortingOption.PRICE_ASCENDING) {
                const a_cost = a.properties.charging_cost || Infinity
                const b_cost = b.properties.charging_cost || Infinity
                return a_cost - b_cost
            }

            if (sortingOption === SortingOption.DISTANCE_ASCENDING) {
                const a_distance = a.properties.distanceFromUser || Infinity
                const b_distance = b.properties.distanceFromUser || Infinity
                return a_distance - b_distance
            }

            if (sortingOption === SortingOption.PRICE_DESCENDING) {
                const a_cost = a.properties.charging_cost || 0
                const b_cost = b.properties.charging_cost || 0
                return b_cost - a_cost
            }

            if (sortingOption === SortingOption.DISTANCE_DESCENDING) {
                const a_distance = a.properties.distanceFromUser || 0
                const b_distance = b.properties.distanceFromUser || 0
                return b_distance - a_distance
            }

            if (sortingOption === SortingOption.PRICE_DIST_ASCENDING) {
                const a_cost = a.properties.norm_charging_cost
                const b_cost = b.properties.norm_charging_cost
                const a_distance = a.properties.norm_distanceFromUser
                const b_distance = b.properties.norm_distanceFromUser
                return a_cost + a_distance - b_cost - b_distance
            }

            // if (sortingOption === SortingOption.PRICE_DIST_DESCENDING) {
            //     const a_cost = a.properties.norm_charging_cost || Infinity
            //     const b_cost = b.properties.norm_charging_cost || Infinity
            //     const a_distance =
            //         a.properties.norm_distanceFromUser || Infinity
            //     const b_distance =
            //         b.properties.norm_distanceFromUser || Infinity
            //     return b_cost + b_distance - a_cost - a_distance
            // }

            // if (sortingOption === SortingOption.OPERATOR_ASCENDING) {
            //     return (
            //         a.properties.company.name ||
            //         ''.localeCompare(b.properties.company.name || '')
            //     )
            // }

            // if (sortingOption === SortingOption.OPERATOR_DESCENDING) {
            //     return (
            //         b.properties.company.name ||
            //         ''.localeCompare(a.properties.company.name || '')
            //     )
            // }

            return 0
        }) || []

    // Remove norm_distanceFromUser and norm_charging_cost from properties
    return sorted.map((location) => {
        delete location.properties.norm_distanceFromUser
        delete location.properties.norm_charging_cost
        return location
    })
}
