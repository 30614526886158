import React from 'react'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'

interface Tab {
    title: string
    content: React.ReactNode
}

function TabsComponent({
    child_tabs,
    tabContentClass,
}: {
    child_tabs: Tab[]
    tabContentClass: string
}) {
    if (!child_tabs || child_tabs.length === 0) return

    return (
        <Tabs className="w-full">
            <TabList className="tabs" role="tablist">
                {child_tabs.map((tab, index) => (
                    <Tab
                        key={index}
                        className="tab tab-bordered text-lg"
                        role="tab"
                    >
                        {tab.title}
                    </Tab>
                ))}
            </TabList>

            {child_tabs.map((tab, index) => (
                <TabPanel key={index}>
                    <div className={tabContentClass}>{tab.content}</div>
                </TabPanel>
            ))}
        </Tabs>
    )
}

export default TabsComponent
