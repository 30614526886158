/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Company } from '../models/Company';
import type { LocationChargingCost } from '../models/LocationChargingCost';
import type { LocationChargingCostGeoJson } from '../models/LocationChargingCostGeoJson';
import type { LocationConcise } from '../models/LocationConcise';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ApiService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Επιστρέφει μόνο τα Locations που ανήκουν στην εταιρεία του χρήστη
     * @returns LocationConcise 
     * @throws ApiError
     */
    public apiCompanyLocationsList(): CancelablePromise<Array<LocationConcise>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/company-locations/',
        });
    }

    /**
     * Επιστρέφει μόνο τα Locations που ανήκουν στην εταιρεία του χρήστη
     * @returns LocationConcise 
     * @throws ApiError
     */
    public apiCompanyLocationsRead({
id,
}: {
id: string,
}): CancelablePromise<LocationConcise> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/company-locations/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Company 
     * @throws ApiError
     */
    public apiListCompaniesList(): CancelablePromise<Array<Company>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/list-companies/',
        });
    }

    /**
     * Get locations within a specified distance or the closest X points to the given lng/lat.
     * @returns LocationChargingCostGeoJson 
     * @throws ApiError
     */
    public apiLocationChargingCostList({
lng,
lat,
maxDistance,
closestX,
limit,
company,
isOpen,
city,
address,
q,
startLng,
startLat,
endLng,
endLat,
routeNo,
bbox,
connectorType,
connectorPowerTypes,
connectorFormat,
fastCharge,
evseCapabilities,
status,
voltage,
amperage,
electricPower,
askedKwh,
askedEuro,
askedDuration,
}: {
/**
 * Longitude
 */
lng?: number,
/**
 * Latitude
 */
lat?: number,
/**
 * Maximum distance in meters
 */
maxDistance?: number,
/**
 * Retrieve the closest X locations (Cannot be used in combination with max_distance)
 */
closestX?: number,
/**
 * Limit the number of results
 */
limit?: number,
/**
 * Operating company id
 */
company?: number,
/**
 * Return only curently open locations
 */
isOpen?: boolean,
/**
 * City
 */
city?: string,
/**
 * Search by address
 */
address?: string,
/**
 * Full text search query
 */
q?: string,
/**
 * Start Longitude
 */
startLng?: number,
/**
 * Start Latitude
 */
startLat?: number,
/**
 * End Longitude
 */
endLng?: number,
/**
 * End Latitude
 */
endLat?: number,
/**
 * Route number, among alternatives (Default: 0)
 */
routeNo?: number,
/**
 * Bounding box in the format minx,miny,maxx,maxy
 */
bbox?: Array<number>,
/**
 * At least one available EVSE with any of the requested connector types (CHADEMO,...)
 */
connectorType?: Array<string>,
/**
 * At least one available EVSE with any of the requested power types (AC_1_PHASE,...)
 */
connectorPowerTypes?: Array<string>,
/**
 * At least one available EVSE with any of the requested connector formats (SOCKET,...)
 */
connectorFormat?: Array<string>,
/**
 * At least one available Connector with max_electric_power >= 50kW
 */
fastCharge?: boolean,
/**
 * At least one available EVSE with any of the requested capabilities (RESERVABLE,...)
 */
evseCapabilities?: Array<string>,
/**
 * At least one available EVSE with any of the requested statuses (AVAILABLE,...)
 */
status?: Array<string>,
/**
 * At least one available Connector with max_voltage >= requested voltage
 */
voltage?: number,
/**
 * At least one available Connector with max_amperage >= requested amperage
 */
amperage?: number,
/**
 * At least one available Connector with max_electric_power >= requested electric_power
 */
electricPower?: number,
/**
 * Charge amount in kWh
 */
askedKwh?: number,
/**
 * Charge amount in total cost (euros)
 */
askedEuro?: number,
/**
 * Charge session duration (minutes)
 */
askedDuration?: number,
}): CancelablePromise<Array<LocationChargingCostGeoJson>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/location-charging-cost/',
            query: {
                'lng': lng,
                'lat': lat,
                'max_distance': maxDistance,
                'closest_x': closestX,
                'limit': limit,
                'company': company,
                'is_open': isOpen,
                'city': city,
                'address': address,
                'q': q,
                'start_lng': startLng,
                'start_lat': startLat,
                'end_lng': endLng,
                'end_lat': endLat,
                'route_no': routeNo,
                'bbox': bbox,
                'connector_type': connectorType,
                'connector_power_types': connectorPowerTypes,
                'connector_format': connectorFormat,
                'fast_charge': fastCharge,
                'evse_capabilities': evseCapabilities,
                'status': status,
                'voltage': voltage,
                'amperage': amperage,
                'electric_power': electricPower,
                'asked_kwh': askedKwh,
                'asked_euro': askedEuro,
                'asked_duration': askedDuration,
            },
        });
    }

    /**
     * Retrieve a location by id, including its charging cost
     * @returns LocationChargingCost 
     * @throws ApiError
     */
    public apiLocationChargingCostRead({
id,
connectorType,
connectorPowerTypes,
connectorFormat,
fastCharge,
evseCapabilities,
status,
voltage,
amperage,
electricPower,
askedKwh,
askedEuro,
askedDuration,
}: {
/**
 * A unique integer value identifying this Σταθμός Φόρτισης.
 */
id: number,
/**
 * At least one available EVSE with any of the requested connector types (CHADEMO,...)
 */
connectorType?: Array<string>,
/**
 * At least one available EVSE with any of the requested power types (AC_1_PHASE,...)
 */
connectorPowerTypes?: Array<string>,
/**
 * At least one available EVSE with any of the requested connector formats (SOCKET,...)
 */
connectorFormat?: Array<string>,
/**
 * At least one available Connector with max_electric_power >= 50kW
 */
fastCharge?: boolean,
/**
 * At least one available EVSE with any of the requested capabilities (RESERVABLE,...)
 */
evseCapabilities?: Array<string>,
/**
 * At least one available EVSE with any of the requested statuses (AVAILABLE,...)
 */
status?: Array<string>,
/**
 * At least one available Connector with max_voltage >= requested voltage
 */
voltage?: number,
/**
 * At least one available Connector with max_amperage >= requested amperage
 */
amperage?: number,
/**
 * At least one available Connector with max_electric_power >= requested electric_power
 */
electricPower?: number,
/**
 * Charge amount in kWh
 */
askedKwh?: number,
/**
 * Charge amount in total cost (euros)
 */
askedEuro?: number,
/**
 * Charge session duration (minutes)
 */
askedDuration?: number,
}): CancelablePromise<LocationChargingCost> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/location-charging-cost/{id}/',
            path: {
                'id': id,
            },
            query: {
                'connector_type': connectorType,
                'connector_power_types': connectorPowerTypes,
                'connector_format': connectorFormat,
                'fast_charge': fastCharge,
                'evse_capabilities': evseCapabilities,
                'status': status,
                'voltage': voltage,
                'amperage': amperage,
                'electric_power': electricPower,
                'asked_kwh': askedKwh,
                'asked_euro': askedEuro,
                'asked_duration': askedDuration,
            },
        });
    }

}
