import '../../css/components/action.css'
import { NavLink } from 'react-router-dom'

import { isMobile } from '@/lib/utils'

function NavLinkElement({ children, ...rest }) {
    return (
        <NavLink className={isMobile() ? 'nav-link-sm' : 'nav-link'} {...rest}>
            {children}
        </NavLink>
    )
}

export default NavLinkElement
