import React, { useEffect, useState, useMemo } from 'react'
import DashboardResultsList from '@/components/dashboard/ResultsList'
import { EmptySearchPlaceholder } from '@/components/dashboard/EmptySearchPlaceholder'
import LocationDetails from '@/components/locations/LocationDetails.tsx'
import { getScreenSize, isMobile } from '@/lib/utils'

import { filterData } from '@/components/dashboard/dashboard_utils'
import { SortingOption, useSortedData } from '@/lib/sorting'

import { LoadingScreen } from '@/components/LoadingScreen'
import { ResultsToolbar } from '@/components/dashboard/ResultsToolbar'

const DesktopLayout = React.lazy(() => import('./LayoutDesktop'))
const MobileLayout = React.lazy(() => import('./LayoutMobile'))

function PriceDashboardLayout({
    data,
    selectedLocation,
    setSelectedLocation,
    dataLoading,
}) {
    const [panelExpanded, setPanelExpanded] = useState(true)
    const [screenSize, setScreenSize] = useState(getScreenSize())
    const [filters, setFilters] = useState({
        selectedOperator: '',
        searchText: '',
        showNullCost: true,
    })

    const sort_options = Object.entries(SortingOption) || []
    const [sortingOption, setSortingOption] = useState(
        sort_options.PRICE_ASCENDING
    )

    useEffect(() => {
        const handleResize = () => {
            setScreenSize(getScreenSize())
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    // Function to handle the panel collapse
    const handlePanelCollapse = (collapsed) => {
        setPanelExpanded(!collapsed)
    }

    // Filtering ----------------------------------------------------------------
    const filteredData = useMemo(
        () =>
            filterData({
                data,
                ...filters,
            }),
        [data, filters]
    )

    const {
        sortedData,
        loading: sorting,
        error: sortingError,
    } = useSortedData(filteredData, sortingOption)

    const LocationsPanel = () => {
        return (
            <div
                className={
                    'flex flex-col h-full gap-2 md:pt-[65px] px-2 ' +
                    (sorting ? ' animate-pulse' : '')
                }
            >
                {selectedLocation && !isMobile() ? (
                    <div className="overflow-y-auto transition-all duration-500 my-auto rounded-t-lg rounded-b-lg h-full m-2 mt-3 mb-3  bg-base-200 bg-opacity-30">
                        <LocationDetails
                            onDashboard={true}
                            selectedLocation={selectedLocation}
                        />
                    </div>
                ) : (
                    <>
                        <ResultsToolbar
                            data={data}
                            sortedData={sortedData}
                            filters={filters}
                            setFilters={setFilters}
                            sortingOption={sortingOption}
                            setSortingOption={setSortingOption}
                        />

                        {!dataLoading &&
                        (!sortedData ||
                            sortedData.length === 0 ||
                            sortingError ||
                            !data ||
                            data?.features?.length === 0) ? (
                            <EmptySearchPlaceholder />
                        ) : (
                            <div className="p-2 h-full">
                                <DashboardResultsList
                                    sortedData={sortedData}
                                    sortingOption={sortingOption}
                                    setSelectedLocation={setSelectedLocation}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        )
    }

    if (isMobile())
        return (
            <React.Suspense fallback={<LoadingScreen />}>
                <MobileLayout LocationsPanel={LocationsPanel} />
            </React.Suspense>
        )

    return (
        <React.Suspense fallback={<LoadingScreen />}>
            <div className="h-full m-0 pb-[48px] pt-0  md:pb-0 overflow-y-hidden">
                <DesktopLayout
                    LocationsPanel={LocationsPanel}
                    handlePanelCollapse={handlePanelCollapse}
                    panelExpanded={panelExpanded}
                    screenSize={screenSize}
                />
            </div>
        </React.Suspense>
    )
}

export { PriceDashboardLayout }
