import React, { createContext, useState, useRef } from 'react'

/*
 * Set the map style based on the time of day
 * @returns {string} - the map style name
 */
function styleBasedOnTime() {
    const date = new Date()
    const hour = date.getHours()
    if (hour >= 6 && hour < 18) {
        return 'basic'
    } else {
        return 'black'
    }
}

// Create the context
const MapContext = createContext()

// Provider component
const MapProvider = function MapProvider({ children }) {
    const mapContainer = useRef(null)
    const [currentMap, setCurrentMap] = useState(null)
    const [loading, setLoading] = useState(true)

    // Map style
    const [mapStyle, setMapStyle] = useState(styleBasedOnTime())

    // View (map or list)
    const [showMap, setShowMap] = useState(false)

    window.setStyle = setMapStyle

    // Create the context value object
    const mapContextValue = {
        mapContainer,
        currentMap,
        setCurrentMap,
        mapStyle,
        setMapStyle,
        showMap,
        setShowMap,
        loading,
        setLoading,
    }

    return (
        <MapContext.Provider value={mapContextValue}>
            {children}
        </MapContext.Provider>
    )
}

export { MapProvider, MapContext }
