import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '@/context/AuthContext'
import { DataContext } from '@/context/DataContext'
import RouteBase from '@/routes/RouteBase'
import LanguageControl from '@/components/LanguageControl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalComponent from '@/components/Modal'
import { Toaster } from 'sonner'
import {
    faAngleDoubleRight,
    faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons'
import { Disclaimer } from '@/components/Disclaimer'

import UserVehiclesWidget from '@/components/accounts/UserVehiclesWidget'
import { ParamFilters } from '@/components/filters/ParamFilters'
import { ChargeAmount } from '@/components/filters/ChargeAmount'

import { useQuery } from '@tanstack/react-query'

/**
 * If a non-authenticated user accesses the dashboard for the first time,
 * he is presented with this screen, to initially set his search parameters
 *
 */
function ParameterWalkthrough() {
    const { t, i18n } = useTranslation()
    const language = i18n.language
    const { disclaimerConsent, setDisclaimerConsent, AuthenticatedAPI } =
        useContext(AuthContext)
    const { setInitialPageLoad, SearchLocations } = useContext(DataContext)

    const [showModal, setShowModal] = useState(
        !disclaimerConsent || disclaimerConsent === 'false' ? true : false
    )

    const acceptDisclaimer = () => {
        setShowModal(false)
        setDisclaimerConsent(true)
    }

    const handleContinue = () => {
        setInitialPageLoad(false)
        SearchLocations()
    }

    const { data: walkthrough_content, isLoading: walkthroughLoading } =
        useQuery({
            queryKey: [`walkthrough_text_${language}`],
            queryFn: () =>
                AuthenticatedAPI.chargingCost.chargingCostApplicationSettingsRead(
                    {
                        name: `walkthrough_text_${language}`,
                    }
                ),
            enabled: true,
            retry: 3,
        })

    return (
        <>
            <ModalComponent
                isOpen={showModal}
                contentLabel={t('disclaimer.title')}
                onRequestClose={() => acceptDisclaimer()}
            >
                <div className="flex flex-col h-full w-full">
                    <div className="relative m-auto bg-white py-5 px-6 rounded-lg border shadow-xl flex flex-col">
                        <div className=" flex w-full justify-between">
                            <h2 className="  text-lg font-bold bg-white pb-2 pt-3 ">
                                {t('Αποποίηση Ευθύνης')}
                            </h2>
                            <LanguageControl />
                        </div>

                        <div className="overflow-y-auto max-h-[70vh] mt-7 pt-2 pb-4 border-t">
                            <Disclaimer />
                        </div>

                        <button
                            className="btn btn-secondary ml-auto"
                            onClick={() => acceptDisclaimer()}
                        >
                            {t(
                                'Έχω διαβάσει και αποδέχομαι την αποποίηση ευθύνης'
                            )}
                        </button>
                    </div>
                </div>
            </ModalComponent>
            <Toaster position="bottom-right" closeButton />
            <RouteBase classNames="flex flex-col overflow-x-hidden">
                <div className="m-auto h-max pt-14 pb-14 px-4 flex flex-col gap-4 items-start max-w-7xl">
                    <h1 className="text-2xl font-bold ">
                        <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            className="mr-2"
                        />
                        {t('Νέα Σύγκριση')}
                    </h1>

                    {walkthroughLoading && <div>Loading...</div>}
                    {
                        // Show walkthrough text from API
                        walkthrough_content && (
                            <div
                                className="p-4 space-y-2 text-gray-600"
                                dangerouslySetInnerHTML={{
                                    __html: walkthrough_content.value,
                                }}
                            />
                        )
                    }

                    <div className="grid md:grid-cols-2 md:gap-x-10 gap-7 w-full mx-auto px-4 py-4 rounded-lg max-w-7xl">
                        <ChargeAmount size="md" className="w-full" />

                        <UserVehiclesWidget />
                        <ParamFilters size="md" className="w-full" />
                    </div>

                    <div className="w-64 mx-auto my-8 flex flex-row ">
                        <button
                            className="btn btn-secondary mr-auto"
                            onClick={handleContinue}
                        >
                            {t('Σύγκριση Τιμών')} &nbsp;
                            <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </button>
                    </div>
                </div>
            </RouteBase>
        </>
    )
}

export { ParameterWalkthrough }
