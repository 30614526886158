import React, { createContext, useState, useEffect } from 'react'
import { CharginCostAPI } from '@/api/CharginCostAPI'
import { IUser, IUserProfile } from '@/types/User'
import { useGoogleLogin } from '@react-oauth/google'
import { ApiError } from '@/api/index'

function writeCookie(name, value, days) {
    var expires = ''
    if (days) {
        var date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        expires = '; expires=' + date.toUTCString()
    }
    document.cookie = name + '=' + value + expires + '; path=/'
}

function readCookie(name) {
    var nameEQ = name + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) === ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
}

function eraseCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999;'
}

type IAuthContext = {
    user: IUser
    csrf: string
    authToken: string
    pending: boolean
    error: Error | ApiError | string | null
    AuthenticatedAPI: CharginCostAPI
    setCSRF: (csrf: string) => void
    setAuthToken: (token: string) => void
    setError: (error: Error | ApiError | string | null) => void
    getCSRF: () => void
    getUserProfile: () => void // Replace UserProfileType with the actual type for userProfile
    login: (username: string, password: string) => void
    logout: () => void
    disclaimerConsent: boolean
}

// Create a context to share data globally
const AuthContext = createContext<IAuthContext>(null)
const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    // State
    const storedAuthToken = localStorage.getItem('authToken')

    const consentCookie = readCookie('disclaimerConsent')
    const [disclaimerConsent, setDisclaimerConsent] = useState(
        consentCookie === 'true'
    )

    const [csrf, setCSRF] = useState('')
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [authToken, setAuthToken] = useState(storedAuthToken || null)
    const [userInfo, setUserInfo] = useState(null)
    const [userProfile, setUserProfile] = useState<IUserProfile>(null)
    const [error, setError] = useState(null)
    const [pending, setPending] = useState(false)
    const [ApiClient, setApiClient] = useState<CharginCostAPI>(
        new CharginCostAPI({
            BASE: `${window.location.origin}${
                import.meta.env.VITE_BACKEND_ROOT || ''
            }`,
        })
    )

    const resendAuthToken = () => {
        ApiClient.auth
            .authResendTokenList()
            .then((data) => {
                setAuthToken(data.key)
            })
            .catch((err) => {
                console.log(err)
                setAuthToken(null)
            })
    }

    // Check if the user is authenticated on the backend, to sync with Session auth
    useEffect(() => {
        if (authToken) return
        ApiClient.auth
            .authWhoamiList()
            .then((data) => {
                resendAuthToken()
            })
            .catch((err) => {
                console.log(err)
                setAuthToken(null)
            })
    }, [])

    useEffect(() => {
        if (authToken) {
            setIsAuthenticated(true)
            localStorage.setItem('authToken', authToken)
        } else {
            setIsAuthenticated(false)
            localStorage.removeItem('authToken')
        }
    }, [authToken])

    useEffect(() => {
        setPending(false)
    }, [isAuthenticated])

    useEffect(() => {
        getCSRF()
    }, [isAuthenticated, error])

    useEffect(() => {
        if (isAuthenticated && !userInfo) {
            getUser()
            getUserProfile()
        }

        if (!isAuthenticated) {
            setUserInfo(null)
            setUserProfile(null)
        }
    }, [isAuthenticated])

    // Refresh the backend API client with new credentials
    useEffect(() => {
        ApiClient.request.config.CREDENTIALS = 'same-origin'
        ApiClient.request.config.HEADERS = {
            'Content-Type': 'application/json',
        }
        csrf ? (ApiClient.request.config.HEADERS['X-CSRFToken'] = csrf) : null
        authToken
            ? (ApiClient.request.config.HEADERS[
                  'Authorization'
              ] = `Token ${authToken}`)
            : null

        if (!isAuthenticated) {
            setApiClient(new CharginCostAPI())
        }

        //console.log(ApiClient.request.config)
    }, [csrf, authToken, isAuthenticated])

    const updateDefaultVehicle = (vehicleId: number) => {
        ApiClient.vehicles
            .vehiclesSetDefaultVehicleUpdate({ defaultVehicle: vehicleId })
            .then((data) => {
                getUserProfile()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getCSRF = () => {
        ApiClient.auth
            .authCsrfList()
            .then((res) => {
                let csrfToken = res.value //res.headers.get("X-CSRFToken");
                setCSRF(csrfToken)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getUser = () => {
        ApiClient.auth
            .authWhoamiList()
            .then((data) => {
                setUserInfo(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getUserProfile = () => {
        ApiClient.auth
            .authUserProfileList()
            .then((data) => {
                setUserProfile(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    /**
     * Common method to implement post-auth actions
     * in both normal and social auth flows
     * @param token
     */
    const _postAuth = (token: string) => {
        setIsAuthenticated(true)
        setError(null)
        setPending(false)
        setAuthToken(token)
    }

    const login = (username: string, password: string) => {
        ApiClient.auth
            .authLoginCreate({
                data: { username, password },
            })
            .then((data) => {
                _postAuth(data.key)
            })
            .catch((err) => {
                console.log('error', err.body)
                setError(err.body)
                setPending(false)
            })
    }

    // Beta
    const googleLogin = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: async (codeResponse) => {
            const token = await fetch('/auth/google-login/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ code: codeResponse.code }),
            })
            const at = await token.json()
            _postAuth(at.key)
        },
        onError: (errorResponse) => {
            console.log(errorResponse)
            setError(errorResponse)
            setPending(false)
        },
        onNonOAuthError: (nonOAuthError) => {
            console.log(nonOAuthError.message)
            setError(nonOAuthError)
            setPending(false)
        },
    })

    const logout = () => {
        ApiClient.auth
            .authLogoutCreate()
            .then((data) => {
                setIsAuthenticated(false)
                setError('')
                setUserInfo({
                    username: null,
                    email: null,
                    first_name: null,
                    last_name: null,
                    is_staff: false,
                    last_login: null,
                    groups: null,
                    user_permissions: null,
                    is_superuser: false,
                    is_rae_admin: false,
                    is_operator_admin: false,
                    is_operator_staff: false,
                    created_by: null,
                    id: null,
                })
                setAuthToken('')
                localStorage.removeItem('authToken')
                localStorage.removeItem('userInfo')
            })
            .catch((err) => {
                console.log(err)
                setAuthToken('')
                localStorage.removeItem('authToken')
                localStorage.removeItem('userInfo')
            })
    }

    // Set disclaimer consent cookie
    useEffect(() => {
        writeCookie('disclaimerConsent', disclaimerConsent, 365)
    }, [disclaimerConsent])

    // Create the context value object
    const contextValue = {
        user: {
            isAuthenticated: isAuthenticated,
            profile: userProfile || {},
            ...(userInfo || {}),
        },
        AuthenticatedAPI: ApiClient,
        csrf,
        authToken,
        pending,
        setPending,
        error,
        updateDefaultVehicle,
        setCSRF,
        setAuthToken,
        setError,
        getCSRF,
        getUserProfile,
        login,
        googleLogin,
        logout,
        disclaimerConsent,
        setDisclaimerConsent,
    }

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider
export { AuthContext, AuthProvider, IAuthContext }
