import React, { useState, useContext, useEffect } from 'react'
import { Select } from 'react-daisyui'
import { useTranslation } from 'react-i18next'

import { AuthContext } from '@/context/AuthContext'
import { DataContext } from '@/context/DataContext'

function UserVehiclesWidget() {
    const { t } = useTranslation()
    const { user, updateDefaultVehicle } = useContext(AuthContext)
    const { setParamsMulti, applyVehicle, setApplyVehicle } =
        useContext(DataContext)

    const [vehicles, setVehicles] = useState(null)
    const [defaultVehicleId, setDefaultVehicleId] = useState(null)

    useEffect(() => {
        if (!user) return

        if (user.profile?.vehicles?.length > 0) {
            setVehicles(user.profile.vehicles)

            if (user.profile.default_vehicle) {
                setDefaultVehicleId(user.profile.default_vehicle)
            } else {
                setDefaultVehicleId(user.profile.vehicles[0].id)
            }
        }
    }, [user])

    useEffect(() => {
        if (!defaultVehicleId) return

        // Update default vehicle on the server
        updateDefaultVehicle(defaultVehicleId)

        const defaultVehicle = vehicles.find(
            (vehicle) => vehicle.id === defaultVehicleId
        )
        const newParams =
            applyVehicle === true
                ? [
                      {
                          name: 'connectorType',
                          value: defaultVehicle.charger_standard,
                      },
                      {
                          name: 'connectorPowerTypes',
                          value: defaultVehicle.power_type,
                      },
                      {
                          name: 'electricPower',
                          value: defaultVehicle.charge_power,
                      },
                  ]
                : [
                      {
                          name: 'connectorType',
                          value: null,
                      },
                      {
                          name: 'connectorPowerTypes',
                          value: null,
                      },
                      {
                          name: 'electricPower',
                          value: null,
                      },
                  ]

        setParamsMulti(newParams)
    }, [defaultVehicleId, applyVehicle])

    if (!user || !user.isAuthenticated) return null
    if (!vehicles) return null

    return (
        <>
            <div
                className="tooltip tooltip-right"
                data-tip={t('Επιλογή Οχήματος')}
            >
                <div className="form-control">
                    <label className="label cursor-pointer">
                        <span className="ml-1 text-xs text-left font-normal">
                            {t('Φιλτράρισμα βάσει του οχήματος μου')}
                        </span>

                        <input
                            type="checkbox"
                            checked={applyVehicle}
                            onChange={() => setApplyVehicle(!applyVehicle)}
                            className="checkbox checkbox-xs checkbox-success"
                        />
                    </label>
                </div>

                {applyVehicle === true ? (
                    <>
                        {' '}
                        <Select
                            className="select-sm m-1 w-full text-gray-700"
                            placeholder={t('Όχημα')}
                            size="xs"
                            value={defaultVehicleId}
                            onChange={(e) => {
                                setDefaultVehicleId(parseInt(e.target.value))
                            }}
                        >
                            {vehicles.map((vehicle) => {
                                return (
                                    <option key={vehicle.id} value={vehicle.id}>
                                        {vehicle.manufacturer}
                                        &nbsp; {vehicle.model}
                                        {vehicle.id === defaultVehicleId &&
                                            `(${t('Προεπιλογή')})`}
                                    </option>
                                )
                            })}
                        </Select>
                    </>
                ) : null}
            </div>
        </>
    )
}

export default UserVehiclesWidget
