import React from 'react'

import { useLocation } from 'react-router-dom'

import { UserPosition } from '@/modules/position/components/UserPosition'
import { LocationFilter } from '@/components/filters/LocationFilter'
import { ParamFilters } from '@/components/filters/ParamFilters'
import { ChargeAmount } from '@/components/filters/ChargeAmount'
import CompanyFilter from '@/components/filters/CompanyFilter'

import RequestDataButton from '@/components/buttons/RequestDataButton'
import LanguageControl from '@/components/LanguageControl'

import { LogoItem, SelectVehicle } from '@/components/sidebar/SidebarItems'

import { Drawer } from 'vaul'

function MobileMenu({ isOpen, setOpen }) {
    const location = useLocation()

    const closeMenu = () => {
        setOpen(false)
    }

    const SearchTools = () => {
        return (
            <>
                <div className="w-full px-6">
                    <LocationFilter className="w-full text-black bg-gray-200 bg-opacity-60 rounded-lg p-2" />

                    <SelectVehicle expanded={true} />
                    <ChargeAmount size="md" className="w-full " />
                    <ParamFilters size="md" className="w-full" />
                </div>
            </>
        )
    }

    return (
        <Drawer.Root dismissible={true} open={isOpen} onClose={closeMenu}>
            <Drawer.Portal>
                <Drawer.Overlay className="fixed inset-0 bg-black/40 z-50  w-full h-full" />
                <Drawer.Content className="bg-base-100 flex flex-col  rounded-t-lg fixed bottom-0 left-0 right-0 z-[150] min-h-[90vh] max-h-[90vh] w-full overflow-x-hidden overflow-y-hidden">
                    <div className="absolute top-0 left-0 right-0 bg-base-100 h-[60px]  p-2  border-b text-gray-800 shadow">
                        {/* Swipe handle */}
                        <div className="flex w-full justify-center">
                            <div className="w-6 h-1 bg-base-200 rounded-full"></div>
                        </div>
                        {/* Header */}
                        <div className="flex items-center">
                            <LogoItem expanded={true} />
                            <LanguageControl small className="ml-auto" />
                        </div>
                    </div>

                    <div className="w-full overflow-y-auto overflow-x-hidden flex flex-col items-center gap-3 mt-14  z-[-1] border-t border-b mx-auto">
                        <div className="w-full bg-sky-100 bg-opacity-60">
                            <UserPosition expanded={true} className="" />
                        </div>

                        {location && location.pathname === '/dashboard' ? (
                            <SearchTools />
                        ) : null}
                    </div>

                    <div className="w-64 mx-auto my-3 flex flex-row ">
                        {location && location.pathname === '/dashboard' ? (
                            <RequestDataButton
                                expanded={true}
                                className="w-full btn-md"
                            />
                        ) : null}
                    </div>
                </Drawer.Content>
            </Drawer.Portal>
        </Drawer.Root>
    )
}

export { MobileMenu }
