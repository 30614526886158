import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { EVSE } from '@/modules/ocpi/types'
import ConnectorDetails from '@/components/connectors/ConnectorDetails'

import {
    formatDateTime,
    formatEditedBefore,
    alertTimeElapsed,
} from '@/lib/utils'
import { isFastCharger } from './utils'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChargingStation,
    faRotate,
    faCircleCheck,
    faCheck,
    faExclamationCircle,
    faBoltLightning,
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next' // Import the translation hook

const LastUpdated = ({ evse }) => {
    const { t } = useTranslation() // Translation hook
    const lastUpdate = formatDateTime(evse.last_updated)
    const updatedLongAgo = alertTimeElapsed(evse.last_updated, {
        minutes: 30,
    })

    if (!evse.last_updated) return null
    return (
        <span
            className="ml-auto text-gray-600 mx-2 tooltip tooltip-left font-medium text-xs"
            data-tip={`${t('evse.updated')} ${lastUpdate}`}
        >
            {updatedLongAgo ? (
                <div className="indicator text-xs">
                    <span className="indicator-item">
                        <FontAwesomeIcon
                            className="text-orange-600 ml-2"
                            icon={faExclamationCircle}
                        />
                    </span>
                    <div className="">
                        <FontAwesomeIcon icon={faRotate} className="mr-2" />
                        {formatEditedBefore(evse.last_updated)}
                    </div>
                </div>
            ) : (
                <>
                    <FontAwesomeIcon icon={faRotate} className="mr-2" />
                    {formatEditedBefore(evse.last_updated)}
                </>
            )}
        </span>
    )
}
interface Props {
    evse: EVSE
    index: number
}

const EvseDetails: React.FC<Props> = ({ evse, index, ...rest }) => {
    const lastUpdate = formatDateTime(evse.last_updated)
    const updatedLongAgo = alertTimeElapsed(evse.last_updated, {
        minutes: 30,
    })

    const { t } = useTranslation() // Translation hook
    // console.log('evse', evse)
    return (
        <div className="w-full h-full min-h-max p-3 pb-1 mb-1 bg-base-200 bg-opacity-30 rounded-lg ">
            <div className="flex flex-row w-full justify-between items-center gap-2">
                <h2 className="md:text-lg">
                    <FontAwesomeIcon icon={faChargingStation} />
                    &nbsp; {t('evse.evse')} {index}
                </h2>
                <span
                    className={
                        evse.status === 'AVAILABLE'
                            ? 'text-success'
                            : 'text-warning'
                    }
                >
                    {t(evse.status)}
                </span>
                <LastUpdated evse={evse} />
            </div>
            <hr className="my-1 border-gray-200" />
            <div className="space-x-4 mb-3">
                {isFastCharger(evse) && (
                    <span>
                        <FontAwesomeIcon
                            icon={faBoltLightning}
                            className="mr-1"
                        />
                        Fast charge
                    </span>
                )}
            </div>

            <Tabs className="w-full h-full">
                <TabList>
                    <Tab className="tab tab-sm py-1 mx-[1px] text-xs">
                        {t('evse.connectors')}
                    </Tab>
                    {evse.capabilities && evse.capabilities.length > 0 && (
                        <Tab className="tab tab-sm  py-1 mx-[1px] text-xs">
                            {t('evse.capabilities')}
                        </Tab>
                    )}
                    <Tab className="tab tab-sm  py-1 mx-[1px] text-xs">
                        {t('evse.info')}
                    </Tab>
                </TabList>
                <TabPanel>
                    <div className="lg:max-h-none overflow-y-hidden  lg:overflow-hidden overflow-x-hidden 2-xl:grid 2-xl:grid-cols-2 xl:gap-1">
                        {evse.connectors.map((connector, index) => (
                            <div
                                key={index}
                                className="h-content rounded-lg bg-[#dae9ef] border border-gray-300  my-1  p-1 px-3"
                            >
                                <ConnectorDetails
                                    connector={connector}
                                    index={index + 1}
                                />
                            </div>
                        ))}
                    </div>
                </TabPanel>
                {evse.capabilities && evse.capabilities.length > 0 && (
                    <TabPanel>
                        <div className="w-full p-2 h-full flex flex-col gap-3 ">
                            <table className="table min-w-full max-w-md ">
                                <tbody>
                                    {evse.capabilities.map((cap) => (
                                        <tr key={cap}>
                                            <td className="font-medium">
                                                {t(cap)}
                                            </td>
                                            <td>
                                                <FontAwesomeIcon
                                                    className="text-green-700 ml-2"
                                                    icon={faCheck}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                    {evse.supports_roaming && (
                                        <tr>
                                            <td className="font-medium">
                                                Roaming{' '}
                                            </td>
                                            <td>
                                                <FontAwesomeIcon
                                                    className="text-green-700 ml-2"
                                                    icon={faCircleCheck}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </TabPanel>
                )}
                <TabPanel>
                    <div className="w-full p-2 h-full flex flex-col gap-3 ">
                        <table className="table min-w-full max-w-md ">
                            <tbody>
                                {evse.manufacturer && (
                                    <tr>
                                        <td className="font-medium">
                                            {t('evse.manufacturer')}
                                        </td>
                                        <td>{evse.manufacturer}</td>
                                    </tr>
                                )}
                                {evse.model_name && (
                                    <tr>
                                        <td className="font-medium">
                                            {t('evse.model')}
                                        </td>
                                        <td>{evse.model_name}</td>
                                    </tr>
                                )}
                                {evse.floor_level && (
                                    <tr>
                                        <td className="font-medium">
                                            {t('evse.floor_level')}
                                        </td>
                                        <td>
                                            <strong>
                                                {evse.floor_level == 0
                                                    ? t('evse.ground_floor')
                                                    : evse.floor_level}
                                            </strong>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </TabPanel>
            </Tabs>
        </div>
    )
}

export default EvseDetails
