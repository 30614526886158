import Type2 from '@/assets/svg/type2.svg'
import Combo1 from '@/assets/svg/ccscombo1.svg'
import Combo2 from '@/assets/svg/ccscombo2.svg'
import Chademo from '@/assets/svg/chademo.svg'

import React from 'react'

function getIcon(type) {
    switch (type) {
        case 'Type 2':
            return Type2
        case 'IEC_62196_T2':
            return Type2
        case 'Type 1 Combo':
            return Combo1
        case 'IEC_62196_T1_COMBO':
            return Combo1
        case 'Type 2 Combo':
            return Combo2
        case 'IEC_62196_T2_COMBO':
            return Combo2

        case 'CHADEMO':
            return Chademo
        case 'CHAdeMO':
            return Chademo

        default:
            return null
    }
}

function ConnectorIcon({
    connector_standard,
    size = 24,
    className = '',
    ...props
}) {
    const icon = getIcon(connector_standard)

    if (!icon) return null
    return (
        <img
            src={icon}
            width={size}
            height={size}
            className={`inline fill-current ${className}`}
            {...props}
        />
    )
}

export { ConnectorIcon }
