import { useTranslation } from 'react-i18next'
import { Connector, SessionInfo as ISessionInfo } from '@/modules/ocpi/types'

import { TariffComponent } from '@/components/tariffs/Tariff'
import { ChargePolicyComponent } from '@/components/tariffs/ChargePolicy'

function SessionInfo({ session_info }: { session_info: ISessionInfo }) {
    const { t } = useTranslation()
    if (!session_info || Object.keys(session_info).length === 0) return null

    const { duration, energy, assumptions, error } = session_info

    return (
        <div className=" ml-auto text-left mb-6">
            <h3 className="font-medium">{t('session_info.title')} </h3>{' '}
            <table className="table-fixed border-separate border-spacing-x-2  border m-2">
                {energy && (
                    <tr>
                        <td>{t('session_info.energy')}:</td>
                        <td>{energy} kWh</td>
                    </tr>
                )}
                {duration && (
                    <tr>
                        <td>{t('session_info.duration')}:</td>
                        <td>
                            {duration} {t('session_info.minutes')}
                        </td>
                    </tr>
                )}
                {assumptions && (
                    <tr>
                        <td>{t('session_info.assumptions')}:</td>
                        <td className="whitespace-normal">
                            {assumptions.map((assumption, idx) => (
                                <div key={idx}>
                                    {t(`session_info.assumption.${assumption}`)}
                                </div>
                            ))}
                        </td>
                    </tr>
                )}
                {error && (
                    <tr>
                        <td></td>
                        <td>{t(`session_info.error.${error}`)}</td>
                    </tr>
                )}
            </table>
        </div>
    )
}

function CostIndicator({
    charging_cost,
}: {
    charging_cost?: Connector['charging_cost']
}) {
    const { t } = useTranslation()

    const displayValue =
        !charging_cost || !charging_cost?.mean_cost
            ? ' - '
            : charging_cost?.mean_cost

    return (
        <>
            <div className="text-secondary font-bold ml-auto text-left my-1">
                <span className="font-normal ">{t('normalized_cost')}</span>{' '}
                {displayValue} €/kWh
            </div>
        </>
    )
}

function CostBreakdown({
    charging_cost,
}: {
    charging_cost: Connector['charging_cost']
}) {
    const { t } = useTranslation()
    if (!charging_cost.details) return null

    if (charging_cost.type === 'ocpi') {
        return (
            <>
                {charging_cost.session_info && (
                    <SessionInfo session_info={charging_cost.session_info} />
                )}
                <h3 className="font-medium">{t('cost_breakdown')}</h3>
                <span className="text-xs font-normal text-gray-500">
                    * {t('vat_excluded')}
                </span>
                {charging_cost.details.map((tariff, idx) => (
                    <TariffComponent tariff={tariff} index={idx} />
                ))}
            </>
        )
    }

    if (charging_cost.type === 'custom') {
        return (
            <>
                <h3 className="">{t('cost_breakdown')}</h3>
                <span className="text-xs font-normal text-gray-500">
                    * {t('vat_excluded')}
                </span>
                <ChargePolicyComponent charge_policy={charging_cost.details} />
            </>
        )
    }

    return null
}

interface Props {
    index?: number
    connector: Connector
}
function ConnectorCostComponent({ connector }: Props) {
    const { t } = useTranslation()
    if (!connector.charging_cost) {
        return null
    }

    if (
        !connector.charging_cost.details ||
        connector.charging_cost.details.length === 0
    ) {
        return (
            <div className="mt-5">
                <CostIndicator charging_cost={connector.charging_cost} />
            </div>
        )
    }

    return (
        <>
            <div className="collapse collapse-sm collapse-arrow bg-[#dae9ef] hover:shadow transition-all rounded-md ">
                <input type="checkbox" />
                <div className="collapse-title h-3">
                    <CostIndicator charging_cost={connector.charging_cost} />
                </div>
                <div className="collapse-content">
                    <CostBreakdown charging_cost={connector.charging_cost} />
                </div>
            </div>
        </>
    )
}

export { ConnectorCostComponent }
