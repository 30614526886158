import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    useMemo,
    useTransition,
    SetStateAction,
} from 'react'
import { useTranslation } from 'react-i18next'
import ILocation from '@/modules/ocpi/types'

import { AuthContext } from '@/context/AuthContext'
import { shallowEqual, pointsDistance } from '@/lib/utils'
import { toast, Toaster } from 'sonner'
import type { CharginCostAPI } from '@/api/CharginCostAPI'
import type { LocationChargingCostGeoJson } from '@/api'
import { useUserPositionStore } from '../modules/position/context'
import { useQueryParamsState } from '@/hooks/useQueryParamState'
import { useShallow } from 'zustand/react/shallow'

interface SearchResult extends GeoJSON.Feature {
    id: number
    geometry: GeoJSON.Feature<GeoJSON.Point>['geometry']
    properties: LocationChargingCostGeoJson
}
interface SearchResponse extends GeoJSON.FeatureCollection {
    features: SearchResult[]
    type: 'FeatureCollection'
}

export interface ISearchFilters {
    // Search from user's location
    lng?: number
    lat?: number
    maxDistance?: number
    closestX?: number
    limit?: number

    company?: number
    // isOpen?: boolean
    connectorType?: Array<string>
    connectorPowerTypes?: Array<string>
    connectorFormat?: Array<string>
    evseCapabilities?: Array<string>
    status?: Array<string>
    // city?: string
    // address?: string
    voltage?: number
    amperage?: number
    electricPower?: number
    askedKwh?: number
    askedEuro?: number
    askedDuration?: number
    q?: string
    distanceFromUser?: number // This is a calculated field, not a filter
    fastCharge?: boolean

    // route params
    startLng?: number
    startLat?: number
    endLng?: number
    endLat?: number
    routeNo?: number

    // Search within area
    bbox?: Array<number>
}

const DataContext = createContext(null)

export const NULL_FILTERS: ISearchFilters = {
    lng: undefined,
    lat: undefined,
    maxDistance: undefined,
    closestX: undefined,
    limit: undefined,
    company: undefined,
    connectorType: undefined,
    connectorPowerTypes: undefined,
    connectorFormat: undefined,
    evseCapabilities: undefined,
    status: ['AVAILABLE'],
    voltage: undefined,
    amperage: undefined,
    electricPower: undefined,
    askedKwh: 50,
    askedEuro: undefined,
    askedDuration: undefined,
    q: undefined,
    distanceFromUser: undefined,
    fastCharge: false,

    // route params
    startLng: undefined,
    startLat: undefined,
    endLng: undefined,
    endLat: undefined,
    routeNo: 0,

    // Search within area
    bbox: undefined,
}

const fetchData = (
    params: ISearchFilters,
    setData: {
        (value: SetStateAction<SearchResponse>): void
        (arg0: any): void
    },
    setDataLoading: {
        (value: SetStateAction<Boolean>): void
        (arg0: boolean): void
    },
    API: CharginCostAPI,
    userPosition: { latitude: any; longitude: any }
) => {
    const setExtendedData = (response) => {
        if (userPosition && response) {
            let dataWithDistance = { ...response }
            dataWithDistance.features = response.features.map((feature) => {
                const [lng, lat] = feature.geometry?.coordinates
                const distance = pointsDistance(
                    userPosition.latitude,
                    userPosition.longitude,
                    lat,
                    lng
                )

                return {
                    ...feature,
                    properties: {
                        ...feature.properties,
                        distanceFromUser: distance,
                    },
                }
            })
            setData(dataWithDistance ? dataWithDistance : response)
        } else {
            setData(response)
        }
    }
    setDataLoading(true)

    API.api
        .apiLocationChargingCostList({
            ...params,
        })
        .then((response) => {
            setExtendedData(response)
            setDataLoading(false)
        })
        .catch((error) => {
            console.log(error.body)
            setDataLoading(false)
        })
}

function DataProvider({ children, ...rest }) {
    const { t } = useTranslation()
    const userPosition = useUserPositionStore(
        useShallow((state) => state.userPosition)
    )

    // useTransition for fetchData
    const [isPending, startTransition] = useTransition()

    const { user, AuthenticatedAPI } = useContext(AuthContext)

    const [initialPageLoad, setInitialPageLoad] = useState(true)

    const [lastUpdated, setLastUpdated] = useState<Date>(null)

    const [data, setData] = useState<SearchResponse | null>(null)
    const [dataLoading, setDataLoading] = useState<Boolean>(false)

    // SELECTED LOCATION
    const [selectedLocation, setSelectedLocation] = useQueryParamsState<
        number | undefined
    >('location', undefined)

    const [selectedLocationDetails, setSelectedLocationDetails] = useState<
        ILocation[] | undefined
    >(undefined)

    // SEARCH FILTERS
    const [params, _setParams] = useState<ISearchFilters>(NULL_FILTERS)
    const setParams = useMemo(
        // Only update if the params change
        () => (p) => {
            if (shallowEqual(params, p)) return
            _setParams(p)
        },
        [params]
    )

    const [paramsDidChange, setParamsDidChange] = useState<Boolean>(false) // track if params changed, to enable/disable the fetch button //trigger a new fetch

    // Use user's vehicle specs instead of manually applying them
    const [applyVehicle, setApplyVehicle] = useState(false)
    const [applyDistance, setApplyDistance] = useState(null) // initially null, to serve as a check of the first fetch of the user's location
    const [chargeMode, setChargeMode] = useState<'kwh' | 'euros' | 'minutes'>(
        'kwh'
    ) // kwh, euros, minutes

    const [firstPositionTrack, setFirstPositionTrack] = useState(true)

    useEffect(() => {
        AuthenticatedAPI.ocpi
            .ocpiLastUpdateList()
            .then((response) => {
                setLastUpdated(new Date(response.updated))
            })
            .catch((error) => {
                console.log(error.body)
            })
    }, [])
    // Every 4 minutes, retrieve the last update timestamp
    useEffect(() => {
        const interval = setInterval(() => {
            AuthenticatedAPI.ocpi
                .ocpiLastUpdateList()
                .then((response) => {
                    setLastUpdated(new Date(response.updated))
                })
                .catch((error) => {
                    console.log(error.body)
                })
        }, 240000)
        return () => clearInterval(interval)
    }, [])

    // When data changes, remove selected location
    useEffect(() => {
        setSelectedLocation(null)
    }, [data])

    // If the user's vehicle is available, set the default charge amount, and apply vehicle
    useEffect(() => {
        if (user.profile === null || user.profile === undefined) {
            setApplyVehicle(false)
            return
        }
        if (user?.profile?.default_vehicle) {
            setApplyVehicle(true)
            setParams({
                ...params,
                asked_kwh: user.profile.default_vehicle.battery_capacity,
                asked_euros: null,
            })
        }
    }, [user.isAuthenticated])

    // Request data from the API
    useEffect(() => {
        setParamsDidChange(true)
    }, [params])

    // When the user changes, reset the params
    useEffect(() => {
        setParams(NULL_FILTERS)
    }, [user.isAuthenticated])

    // Query params setter
    // name: name of the query param (e.g. 'search', 'type')
    // value: value of the query param (e.g. 'searchValue', 'typeValue'
    function setQueryParam(name: string, value: any) {
        const newParams = { ...params }
        newParams[name] = value
        setParams(newParams)
    }

    function setQueryParamsMulti(paramArray: { name: string; value: any }[]) {
        const newParams = { ...params }

        paramArray.forEach((paramObj) => {
            newParams[paramObj.name] = paramObj.value
        })

        setParams(newParams)
    }

    // Get location details
    // If the selectedLocation changes, fetch the location details
    useEffect(() => {
        if (!selectedLocation) return
        const fetchDetailsData = async () => {
            AuthenticatedAPI.api
                .apiLocationChargingCostRead({
                    id: selectedLocation,

                    ...params,
                })
                .then((response) => {
                    setSelectedLocationDetails(response)
                })
                .catch((error) => {
                    console.log(error.body)
                    setSelectedLocationDetails(null)
                })
        }
        fetchDetailsData()
    }, [selectedLocation])

    const SearchLocations = (extra_params?: Partial<ISearchFilters>) => {
        setParamsDidChange(false)
        startTransition(() => {
            fetchData(
                { ...params, ...extra_params },
                setData,
                setDataLoading,
                AuthenticatedAPI,
                userPosition
            ) // debouncedFetchData
        })
    }

    useEffect(() => {
        if (dataLoading) {
            toast.loading(t('Αναζήτηση σταθμών...'), {
                position: 'top-center',
            })
        }
        if (!dataLoading) {
            toast.dismiss()
        }
    }, [dataLoading])

    const contextValue = {
        initialPageLoad,
        setInitialPageLoad,

        lastUpdated,

        firstPositionTrack,
        setFirstPositionTrack,

        data,
        setData,
        params,
        setParam: setQueryParam,
        setParamsMulti: setQueryParamsMulti,
        overwriteParams: setParams,

        applyVehicle,
        setApplyVehicle,
        applyDistance,
        setApplyDistance,
        chargeMode,
        setChargeMode,

        dataLoading,
        setDataLoading,

        selectedLocation:
            selectedLocation && data && data.features.length > 0
                ? data.features.find(
                      (feature) => feature.id === selectedLocation
                  )
                : null,
        setSelectedLocation: (location: ILocation) => {
            location
                ? setSelectedLocation(parseInt(location.id))
                : setSelectedLocation(undefined)
        },

        selectedLocationDetails,

        paramsDidChange,
        setParamsDidChange,
        SearchLocations,
    }

    return (
        <DataContext.Provider value={contextValue}>
            {children}
            <Toaster />
        </DataContext.Provider>
    )
}
export { DataContext, DataProvider }
