import { useTranslation } from 'react-i18next'
import { OpeningTimes, RegularHour } from '@/modules/ocpi/types'

/**
 *
 * @param day (1-7)
 * @returns string
 */
export const getDayName = (day: number) => {
    const { t } = useTranslation()
    switch (day) {
        case 1:
            return t('Δευτέρα')
        case 2:
            return t('Τρίτη')
        case 3:
            return t('Τετάρτη')
        case 4:
            return t('Πέμπτη')
        case 5:
            return t('Παρασκευή')
        case 6:
            return t('Σάββατο')
        case 7:
            return t('Κυριακή')
    }
}

/*
 * Get the current weekday (1-7)
 **/
export const getDay = () => {
    const now = new Date()
    // return now.getDay()
    return now.getDay() === 0 ? 7 : now.getDay()
}

/**
 * Check if the location is open at the moment
 * @param opening_times
 * @returns boolean
 *
 */
export const checkIsOpen = (opening_times: OpeningTimes[]) => {
    const now = new Date()
    const day = getDay()
    const hours = now.getHours()
    const minutes = now.getMinutes()

    const { twentyfourseven, regular_hours } = opening_times[0]

    if (twentyfourseven) return true

    if (regular_hours) {
        const current_day = regular_hours.find(
            (itinerary_item: RegularHour) => itinerary_item.weekday === day
        )

        if (current_day) {
            const current_time = parseInt(
                `${hours}${minutes < 10 ? '0' + minutes : minutes}`
            )
            const period_begin = parseInt(
                current_day.period_begin.replace(':', '')
            )
            const period_end = parseInt(current_day.period_end.replace(':', ''))

            // Special case: if period ends on midnight, add 2400 to period_end
            if (period_end === 0) {
                if (current_time >= period_begin && current_time <= 2400) {
                    return true
                }
            }

            if (current_time >= period_begin && current_time <= period_end) {
                return true
            }
        }
    }

    return false
}

/**
 * Check if the location is closing in the next 1 hour,
 * and return the minutes left until closing
 *
 * @param opening_times OpeningTimes
 * @returns boolean | number
 */
export const checkIsClosingSoon = (opening_times: OpeningTimes[]) => {
    const now = new Date()
    const day = now.getDay()
    const hours = now.getHours()
    const minutes = now.getMinutes()

    const { twentyfourseven, regular_hours } = opening_times[0]

    if (twentyfourseven) return false

    if (regular_hours) {
        const current_day = regular_hours.find(
            (itinerary_item: RegularHour) => itinerary_item.weekday === day
        )

        if (current_day) {
            const current_time = parseInt(
                `${hours}${minutes < 10 ? '0' + minutes : minutes}`
            )
            const period_end = parseInt(current_day.period_end.replace(':', ''))

            if (current_time >= period_end - 60 && current_time <= period_end) {
                return period_end - current_time
            }
        }
    }

    return false
}
