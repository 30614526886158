/**
 * Module for user position related functions
 */
import { point, distance } from '@turf/turf'

export function arePositionsEqual(
    l1: GeolocationCoordinates,
    l2: GeolocationCoordinates
) {
    if (!l1 || !l2) return false
    if (l1.longitude !== l2.longitude) return false
    if (l1.latitude !== l2.latitude) return false
    return true
}

export function positionsDistance(
    l1: GeolocationCoordinates,
    l2: GeolocationCoordinates
) {
    if (!l1 || !l2) return null
    return distance(
        point([l1.longitude, l1.latitude]),
        point([l2.longitude, l2.latitude]),
        { units: 'kilometers' }
    )
}

/**
 * Check if the user location has moved more than a specified threshold
 * with respect away from the previous location.
 * The threshold is in kilometers (default is 1 km)
 */
export function locationDidMove(
    l1: GeolocationCoordinates,
    l2: GeolocationCoordinates,
    threshold: number = 1
) {
    if (!l1 || !l2) return true
    if (arePositionsEqual(l1, l2)) return false

    const distance = positionsDistance(l1, l2)
    if (distance > threshold) return true

    return false
}
