import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCrosshairs,
    faExclamationTriangle,
    faInfoCircle,
    faLocationCrosshairs,
    faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

import { useUserPosition } from '../context'

function format_accuracy(accuracy) {
    if (accuracy > 1000) {
        return `${(accuracy / 1000).toFixed(2)} Km`
    }

    return `${accuracy.toFixed(2)} m`
}

// m/s to Km/h
function formatSpeed(speed) {
    return `${(speed * 3.6).toFixed(2)} Km/h`
}

function formatAltitude(altitude) {
    return `${altitude.toFixed(2)} m`
}

export function UserPosition({ expanded = true, className, ...rest }) {
    const { t } = useTranslation()
    const {
        userPosition,
        userAddress,
        locationLoading,
        locationError,
        startTracking,
    } = useUserPosition()
    const [accuracyClass, setAccuracyClass] = useState('text-primary')

    useEffect(() => {
        if (!userPosition) return

        if (userPosition.accuracy < 10) {
            setAccuracyClass('text-green-400')
        } else if (userPosition.accuracy < 20) {
            setAccuracyClass('text-orange-400')
        } else {
            setAccuracyClass('text-red-400')
        }
    }, [userPosition])

    // Loading
    if (locationLoading) {
        return (
            <div className={'p-2 ' + className}>
                <p className="text-xs ">
                    <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                    {t('Φορτώνει')}
                </p>
                <p className="text-xs mt-2 text-gray-500">
                    <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="mr-2 text-blue-100"
                    ></FontAwesomeIcon>
                    {t(
                        'Επιτρέψτε στην εφαρμογή να αποκτήσει πρόσβαση στη θέση της συσκευής σας'
                    )}
                </p>
            </div>
        )
    }

    // Set
    if (userPosition) {
        return (
            <>
                <div
                    className={
                        'collapse collapse-arrow rounded-lg mx-4 md:mx-0 ' +
                        className
                    }
                    style={{
                        visibility: expanded ? 'visible' : 'inherit',
                    }}
                >
                    <input
                        type="checkbox"
                        aria-label="User location widget collapse control"
                    />
                    <div className="collapse-title overflow-hidden truncate ">
                        <span className="text-xs font-extrabold text-green-300  w-full">
                            <FontAwesomeIcon
                                icon={faLocationCrosshairs}
                                className="mr-2"
                            />
                            {t('Η θέση μου')}
                        </span>
                        <br />
                        {userAddress ? (
                            <span
                                className="text-xs mt-2 truncate text-left w-[90%]"
                                data-tip={userAddress.display_name}
                            >
                                {userAddress.display_name}
                            </span>
                        ) : null}
                    </div>
                    <div className="collapse-content ">
                        <div className="flex flex-col w-content m-auto ">
                            {userAddress ? (
                                <p
                                    className="text-xs mt-2 font-normal md:text-gray-400 text-left"
                                    data-tip={userAddress.display_name}
                                >
                                    {userAddress.display_name}
                                    {' ('}
                                    <a
                                        className="text-blue-500 md:text-blue-200 hover:underline"
                                        target="_blank"
                                        href="https://nominatim.openstreetmap.org/ui/search.html"
                                    >
                                        Nominatim
                                    </a>
                                    {')'}
                                </p>
                            ) : null}

                            <div className="border-t  my-2"></div>

                            <p className="text-xs mt-1 ">
                                {t('Ακρίβεια')}:
                                <span className={accuracyClass}>
                                    {accuracyClass === 'text-red-400' ? (
                                        <FontAwesomeIcon
                                            icon={faExclamationTriangle}
                                            className="ml-2 mr-1"
                                        />
                                    ) : null}
                                    {userPosition.accuracy
                                        ? format_accuracy(userPosition.accuracy)
                                        : '?'}{' '}
                                </span>
                            </p>
                            {userPosition.speed ? (
                                <p className="text-xs mt-1 text-orange-100">
                                    {t('Ταχύτητα')}:{' '}
                                    {formatSpeed(userPosition.speed)}
                                </p>
                            ) : null}
                            {userPosition.altitude ? (
                                <p className="text-xs mt-1 text-orange-100">
                                    {t('Υψόμετρο')}:{' '}
                                    {formatAltitude(userPosition.altitude)}
                                </p>
                            ) : null}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    // Not Set
    return (
        <div className={'p-2 ' + className}>
            <p className="text-xs font-bold">{t('Τοποθεσία Ανενεργή')}</p>

            <p className="text-xs text-gray-500 mt-2">
                <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="mr-2 text-blue-300"
                ></FontAwesomeIcon>
                {t(
                    'Ενεργοποιείστε την τοποθεσία σας για να βελτιώσετε τα αποτελέσματα της αναζήτησης και να δείτε προσεγγιστικές αποστάσεις προς τους σταθμούς φόρτισης'
                )}
            </p>

            <button
                className="btn btn-xs btn-info btn-outline mt-3"
                onClick={startTracking}
            >
                <FontAwesomeIcon icon={faCrosshairs} className="mr-2" />
                {t('Ενεργοποίηση')}
            </button>
            {locationError ? (
                <div className="mt-2 pt-1 text-xs text-red-300 font-extralight border-t border-gray-800">
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="mr-2"
                    />
                    {t('Σφάλμα')}: {locationError.message}
                </div>
            ) : null}
        </div>
    )
}

export default UserPosition
