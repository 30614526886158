import React from 'react'
import { useNavigate } from 'react-router-dom'
import RouteBase from '../RouteBase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlassLocation } from '@fortawesome/free-solid-svg-icons'

const NotFound = () => {
    const navigate = useNavigate()

    const handleGoBack = () => {
        navigate(-1)
    }

    return (
        <RouteBase classNames="flex flex-col content-center items-center h-full">
            <div className="flex flex-col content-center items-center h-full">
                <div className="m-auto w-96 h-content mt-auto">
                    <h1 className="text-4xl">
                        <FontAwesomeIcon icon={faMagnifyingGlassLocation} />
                        <br />
                        Σφάλμα 404 - Δε βρέθηκε
                    </h1>
                    <p>
                        Η Σελίδα που ζητήσατε δεν υπάρχει. Δοκιμάστε κάποια άλλη
                        διεύθυνση, ή γυρίστε{' '}
                        <span className="link" onClick={handleGoBack}>
                            πίσω
                        </span>
                    </p>
                </div>
            </div>
        </RouteBase>
    )
}

export default NotFound
