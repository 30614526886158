import React from 'react'
import { useTranslation } from 'react-i18next'

import { ChargePolicy } from '@/api/models/ChargePolicy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faRotate,
    faCheckCircle,
    faExclamationCircle,
    faExclamationTriangle,
    faInfoCircle,
    faBolt,
    faClock,
    faReceipt,
} from '@fortawesome/free-solid-svg-icons'
import {
    formatDateTime,
    formatEditedBefore,
    alertTimeElapsed,
} from '@/lib/utils'

// Each tier only has start. We need to add the end, which is the start of the previous tier -1
const addTierEnds = (tiers) => {
    const newTiers = tiers.map((tier, index) => {
        if (index === tiers.length - 1) return { ...tier, end: Infinity }
        return { ...tier, end: tiers[index + 1].start - 1 }
    })
    return newTiers
}

const discountLabel = (discountType) => {
    switch (discountType) {
        case 'percentage':
            return '% της τελικής χρέωσης'
        case 'perKwh':
            return '€/kWh'
        default:
            return null
    }
}

const chargeUnits = (chargeType) => {
    switch (chargeType) {
        case 'time':
            return 'λεπτό'
        case 'energy':
            return 'kWh'
        default:
            return null
    }
}

const chargeIcon = (chargeType) => {
    switch (chargeType) {
        case 'time':
            return <FontAwesomeIcon icon={faClock} className="mr-1" />
        case 'energy':
            return <FontAwesomeIcon icon={faBolt} className="mr-1" />
        default:
            return null
    }
}

const UpdatedInfoComponent = ({ last_updated }) => {
    const { t } = useTranslation()
    if (!last_updated) return null
    const lastUpdate = formatDateTime(last_updated)
    const updatedLongAgo = alertTimeElapsed(last_updated, {
        days: 1,
    })

    return (
        <span
            className={`ml-2 text-gray-500  tooltip tooltip-bottom font-medium`}
            data-tip={
                t('Οι πληφορορίες χρέωσης ενημερώθηκαν/τροποποιήθηκαν') +
                ' ' +
                lastUpdate
            }
        >
            {updatedLongAgo ? (
                <div className="indicator">
                    <span className="indicator-item">
                        <FontAwesomeIcon
                            className="text-orange-500 ml-2"
                            icon={faExclamationCircle}
                        />
                    </span>
                    <div className="">
                        <FontAwesomeIcon icon={faRotate} className="mr-2" />
                        {formatEditedBefore(last_updated)}
                    </div>
                </div>
            ) : (
                <>
                    <FontAwesomeIcon icon={faRotate} className="mr-2" />
                    {formatEditedBefore(last_updated)}
                </>
            )}
        </span>
    )
}

interface Props {
    charge_policy: ChargePolicy
}
const ChargePolicyComponent: React.FC<Props> = ({ charge_policy }) => {
    const { t } = useTranslation()
    if (!charge_policy) return null

    const {
        valid_from,
        valid_to,
        chargeType,
        kWhPrice,
        tieredPricing,
        flatFee,
        flatFeePrice,
        discount,
        discountType,
        discountValue,
        additionalCharges,
        additionalChargesInfo,
        additionalBenefits,
        additionalBenefitsInfo,
        last_updated,
        tiers,
    } = charge_policy

    const ChargeType = () => {
        switch (chargeType) {
            case 'time':
                return (
                    <div className="flex flex-row w-full">
                        <span className="font-bold text-gray-600">
                            {t('Τύπος χρέωσης:')}{' '}
                        </span>
                        <div className="rounded-md bg-slate-800 text-gray-200 font-bold px-2 w-content ml-3">
                            {t('ανά λεπτό (χρονοχρέωση)')}
                        </div>
                    </div>
                )

            case 'energy':
                return (
                    <div className="flex flex-row w-full">
                        <span className="font-bold text-gray-600">
                            {t('Τύπος χρέωσης:')}{' '}
                        </span>
                        <div className="rounded-md bg-slate-800 text-gray-200 font-bold px-2 w-content ml-3">
                            {t('ανά kWh')}
                        </div>
                    </div>
                )
            default:
                return null
        }
    }

    const Updated = () => {
        return (
            <div className="flex flex-row w-full">
                <span className="font-bold text-gray-600">
                    {t('Ενημερώθηκε')}{' '}
                </span>
                <UpdatedInfoComponent last_updated={last_updated} />
            </div>
        )
    }

    const AdditionalCharges = () => {
        if (!additionalCharges) return null
        return (
            <div className="flex flex-col rounded-lg shadow-sm  p-1">
                <div className="font-bold text-gray-600">
                    {t('Επιπλέον Χρεώσεις')}{' '}
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="mr-1 text-red-400"
                    />
                </div>
                <div className="text-blue-600 font-medium">
                    {additionalChargesInfo.description}
                </div>
                <div className="text-gray-600">
                    {additionalChargesInfo.details}
                </div>
            </div>
        )
    }

    const AdditionalBenefits = () => {
        if (!additionalBenefits) return null
        return (
            <div className="flex flex-col rounded-lg shadow-sm  p-1">
                <div className="font-bold text-gray-600">
                    {t('Επιπλέον Παροχές')}{' '}
                    <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="mr-1 text-blue-800"
                    />
                </div>
                <div className="text-blue-600 font-medium">
                    {additionalBenefitsInfo.description}
                </div>
                <div className="text-gray-600">
                    {additionalBenefitsInfo.details}
                </div>
            </div>
        )
    }

    const DiscountInfo = () => {
        return (
            <div className="flex flex-col rounded-lg shadow-sm p-1">
                {discount == true ? (
                    <>
                        <div className="font-bold text-gray-600">
                            {t('Έκπτωση μέλους')}{' '}
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                className="mr-1 text-green-700"
                            />
                        </div>
                        <div>
                            <span className="text-gray-600">
                                {t('Τύπος Έκπτωσης')}:{' '}
                            </span>
                            <span className="text-slate-500 font-bold">
                                {(parseFloat(discountValue) * 100).toFixed(1)}
                                {t(discountLabel(discountType))}
                            </span>
                        </div>
                    </>
                ) : null}
            </div>
        )
    }

    const FlatFeeTag = () => {
        return (
            <div className="flex flex-col rounded-lg bg-blue-200 p-1 px-2 text-xs font-medium cursor-pointer hover:bg-opacity-70 transition-all">
                <h4 className="text-slate-400 font-medium">
                    <FontAwesomeIcon icon={faReceipt} className="mr-1" />
                    {t('Πάγιο')}{' '}
                </h4>
                <span className="text-blue-500 font-bold">
                    {flatFeePrice} €
                </span>
            </div>
        )
    }

    const PriceTag = ({ price }) => {
        return (
            <div className="flex flex-col rounded-lg bg-blue-200 p-1 px-2 text-xs font-medium cursor-pointer hover:bg-opacity-70 transition-all">
                <h4 className="text-slate-400 font-medium">{t('Ενέργεια')} </h4>
                <span className="text-blue-500 font-bold">
                    {price} €/{chargeUnits(chargeType)}
                </span>
            </div>
        )
    }

    const TierTag = ({ tier, index }) => {
        const { start, end, price } = tier
        const range = end === Infinity ? `${start}+` : `${start}-${end}`
        return (
            <div
                className="flex flex-col rounded-lg bg-blue-200 p-1 px-2 text-xs font-medium cursor-pointer hover:bg-opacity-70 transition-all"
                key={index}
            >
                <h4 className="text-slate-400 font-medium">
                    {chargeIcon(chargeType)} {t('Ενέργεια')}
                </h4>
                <span className="text-gray-600 min-">
                    {range} {chargeUnits(chargeType)}
                </span>
                <span className="text-blue-500 font-bold">
                    {price} €/{chargeUnits(chargeType)}
                </span>
            </div>
        )
    }

    const TieredPricing = () => {
        if (!tieredPricing) return null
        return (
            <>
                {addTierEnds(tiers).map((tier, index) => (
                    <TierTag tier={tier} index={index} />
                ))}
            </>
        )
    }

    return (
        <div className="mt-5 flex flex-col gap-2">
            <ChargeType />
            <Updated />
            {tieredPricing === true ? (
                <div className="font-bold text-gray-600">
                    {t('Κλιμακωτή Χρέωση')}{' '}
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                </div>
            ) : null}
            <div className="tabs gap-1 w-full p-0 ">
                {tieredPricing === true ? (
                    <TieredPricing />
                ) : (
                    <PriceTag price={kWhPrice} />
                )}
                <FlatFeeTag />
            </div>
            <DiscountInfo />
            <AdditionalCharges />
            <AdditionalBenefits />
        </div>
    )
}

export { ChargePolicyComponent }
