import React from 'react'
import Modal from 'react-modal'

import { Drawer } from 'vaul'
import { isMobile } from '@/lib/utils'

const customStyles = {
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100vh',
        width: '100vw',
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        zIndex: 1000,
        overflowY: 'auto',
        display: 'flex',
        padding: 'none',
    },
    content: {
        margin: 'auto',
        outline: 'none',
        padding: '1px',
        border: '1px solid rgba(204, 204, 204, 0)',
        background: 'rgba(204, 204, 204, 0)',
        display: 'flex',
    },
}

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

const Desktop = ({ isOpen, onClose, contentLabel, children }) => {
    if (!isOpen) return null

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={customStyles}
            contentLabel={contentLabel}
        >
            {children}
        </Modal>
    )
}

const Mobile = ({ isOpen, onClose, contentLabel, children }) => {
    if (!isOpen) return null

    return (
        <Drawer.Root dismissible={true} open={isOpen} onClose={onClose}>
            <Drawer.Portal>
                <Drawer.Overlay className="fixed inset-0 bg-black/40 z-50  w-full h-full" />
                <Drawer.Content className="bg-base-100 flex flex-col  rounded-t-lg fixed bottom-0 left-0 right-0 z-[150] min-h-[90vh] max-h-[90vh] w-full overflow-x-hidden overflow-y-hidden">
                    {children}
                </Drawer.Content>
            </Drawer.Portal>
        </Drawer.Root>
    )
}

const ModalComponent = ({ isOpen, onClose, contentLabel, children }) => {
    return isMobile() ? (
        <Mobile isOpen={isOpen} onClose={onClose} contentLabel={contentLabel}>
            {children}
        </Mobile>
    ) : (
        <Desktop isOpen={isOpen} onClose={onClose} contentLabel={contentLabel}>
            {children}
        </Desktop>
    )
}

export default ModalComponent
