import { useContext } from 'react'
import { MapContext } from '@/context/MapContext'

export function useMapZoomTo() {
    const { currentMap } = useContext(MapContext)

    const handleMapZoom = (coordinates) => {
        currentMap.flyTo({
            center: coordinates,
            zoom: 15,
            essential: true,
            speed: 0.5,
        })
    }

    return handleMapZoom
}
