import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faLocationPin,
    faFingerprint,
    faAddressCard,
    faBuilding,
} from '@fortawesome/free-solid-svg-icons'

export function LocationInformation({ locationData }) {
    const { t } = useTranslation()

    // console.info('LocationInformation', locationData)
    return (
        <>
            <p>
                <FontAwesomeIcon icon={faFingerprint} /> {t('location.id')}:{' '}
                <span className="font-medium text-secondary ">
                    {locationData.location_id}
                </span>
            </p>
            <p className="">
                <FontAwesomeIcon icon={faLocationPin} className="mr-2" />
                {t('location.address')}:{'  '}
                {locationData.address}, {locationData.city}{' '}
                {locationData.postal_code}
            </p>
            <p>
                <FontAwesomeIcon icon={faBuilding} className="mr-2" />
                {t('location.owner')}:{' '}
                <span className="font-bold ">
                    {locationData.owner || ' - '}
                </span>
            </p>
            <p>
                {t('location.administrator')}:{' '}
                <span className="font-bold ">
                    {locationData.operator || ' - '}
                </span>
            </p>

            <div className="my-6">
                <h3 className="">
                    <FontAwesomeIcon icon={faAddressCard} />{' '}
                    {t('location.feyfho')} (Φ.Ε.Υ.Φ.Η.Ο.)
                </h3>

                <div className="flex justify-between gap-3 p-2">
                    <div>
                        <a
                            href={locationData.company?.WebSite}
                            target="_blank"
                            rel="noreferrer"
                            className="font-bold text-primary underline blue-800 text-lg"
                        >
                            {locationData.company?.name}
                        </a>
                        <p className="text-xs">
                            {locationData.company?.legal_name}
                        </p>
                    </div>
                    <img
                        src={`https://electrokinisi.yme.gov.gr/public/images/${locationData.company?.ProfileImage}`}
                        alt=""
                        width="130"
                        className="rounded"
                    />
                </div>
            </div>
        </>
    )
}
