import React from 'react'
import { useTranslation } from 'react-i18next'
import { Cookies } from 'react-cookie-consent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import cookieConfig from './cookie.config.json'

// MUST be prefixed with VITE_
// see: https://vitejs.dev/guide/env-variables.html
const ANALYTICS_ID = import.meta.env.VITE_GOOGLE_ANALYTICS_ID
const ANALYTICS_COOKIE_ID = '_ga_' + ANALYTICS_ID.replace('G-', '')

function CookieDetails() {
    const { i18n, t } = useTranslation()
    const lang = i18n.language

    const activeCookies = Cookies.get()
    const uncategorizedCookies = Object.keys(activeCookies)
        .filter((cookie) => !Object.keys(cookieConfig.cookies).includes(cookie))
        .filter((cookie) => cookie !== ANALYTICS_COOKIE_ID) // already accounted as _ga_#

    return (
        <div className="h-full overflow-y-auto">
            {cookieConfig.categories.map((item) => (
                <div
                    key={item.tag}
                    className="collapse collapse-arrow border-b rounded-none dark:border-gray-400"
                >
                    <input type="checkbox" />
                    <div className="collapse-title text-xl font-medium">
                        {item[`title_${lang === 'en' ? 'en' : 'gr'}`]}
                    </div>
                    <div className="collapse-content">
                        <p className="pb-1 mb-3 border-b border-gray-100 dark:border-gray-800">
                            {item[`content_${lang === 'en' ? 'en' : 'gr'}`]}
                        </p>
                        <div className="flex flex-col gap-1">
                            {Object.entries(cookieConfig.cookies)
                                .filter(
                                    ([name, cookie], idx) =>
                                        cookie.category === item.tag
                                )
                                .map(([name, cookie], idx) => (
                                    <div className="flex flex-col gap-1 bg-gray-100 dark:bg-gray-700 rounded-lg p-2">
                                        <div
                                            key={name}
                                            className="flex justify-between"
                                        >
                                            <span className="font-bold">
                                                {name}
                                            </span>
                                            {cookie.issuer && (
                                                <a
                                                    className="link text-blue-700 dark:text-blue-300 text-sm"
                                                    href={
                                                        cookieConfig.vendorUrls[
                                                            cookie.issuer
                                                        ]
                                                    }
                                                    target="_blank"
                                                >
                                                    {cookie.issuer}
                                                    <FontAwesomeIcon
                                                        icon={faExternalLinkAlt}
                                                        className="ml-1"
                                                    />
                                                </a>
                                            )}
                                        </div>
                                        <div className="text-sm">
                                            {
                                                cookie[
                                                    `description_${
                                                        lang === 'en'
                                                            ? 'en'
                                                            : 'gr'
                                                    }`
                                                ]
                                            }
                                        </div>
                                        <div className="flex w-full">
                                            {cookie.maxAge && (
                                                <div className=" mt-2">
                                                    <strong>
                                                        {t(
                                                            'cookie_consent.cookie_expiration'
                                                        )}
                                                        :{' '}
                                                    </strong>
                                                    {cookie.maxAge}{' '}
                                                    {
                                                        cookie[
                                                            `max_age_label_${
                                                                lang === 'en'
                                                                    ? 'en'
                                                                    : 'gr'
                                                            }`
                                                        ]
                                                    }
                                                </div>
                                            )}

                                            {cookie.type ? (
                                                <div className="mt-2 ml-auto">
                                                    <strong>
                                                        {t(
                                                            'cookie_consent.cookie_type'
                                                        )}
                                                        :{' '}
                                                    </strong>
                                                    {cookie.type}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            ))}

            {/* Other (tag=undefined) */}
            {uncategorizedCookies.length > 0 && (
                <div className="collapse collapse-arrow">
                    <input type="checkbox" />
                    <div className="collapse-title text-xl font-medium">
                        Other
                    </div>
                    <div className="collapse-content">
                        <p>These cookies are not categorized.</p>
                        <div className="bg-gray-100 rounded-lg p-2">
                            {Object.keys(activeCookies)
                                .filter(
                                    (cookie) =>
                                        !Object.keys(
                                            cookieConfig.cookies
                                        ).includes(cookie)
                                )
                                .map((cookie) => (
                                    <div key={cookie}>{cookie}</div>
                                ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CookieDetails
