import React from 'react'
import { ResultsListItem } from '@/components/dashboard/ResultsListItem'
// import { shallowEqual } from '@/lib/utils'

import { VList } from 'virtua'
import AutoSizer from 'react-virtualized-auto-sizer'

// const arePropsEqual = (prevProps, nextProps) => {
//     return (
//         shallowEqual(prevProps.sortedData, nextProps.sortedData) &&
//         prevProps.sortingOption == nextProps.sortingOption
//     )
// }

const _LocationsList = ({ sortedData, setSelectedLocation, sortingOption }) => {
    return (
        <AutoSizer>
            {({ index, height, width }) => (
                <VList
                    style={{
                        height: height,
                        width: width,
                        overflowX: 'hidden',
                        paddingRight: 5,
                    }}
                >
                    {sortedData.map((location, index) => (
                        <div className="m-1">
                            <ResultsListItem
                                key={index}
                                location={location}
                                setSelected={setSelectedLocation}
                            />
                        </div>
                    ))}
                </VList>
            )}
        </AutoSizer>
    )
}

export default _LocationsList
// React.memo(_LocationsList, arePropsEqual)
