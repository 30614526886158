import React from 'react'
import { Connector } from '@/modules/ocpi/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faEuroSign,
    faRotate,
    faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons'
import {
    formatDateTime,
    formatEditedBefore,
    alertTimeElapsed,
} from '@/lib/utils'
import { ConnectorIcon } from '@/components/connectors/ConnectorIcon'
import { ConnectorCostComponent } from '@/components/tariffs/ConnectorCostComponent'
import { useTranslation } from 'react-i18next' // Import the useTranslation hook

const UpdatedInfoComponent = ({ last_updated, index }) => {
    if (!last_updated) return null
    const lastUpdate = formatDateTime(last_updated)
    const updatedLongAgo = alertTimeElapsed(last_updated, {
        days: 1,
    })

    const { t } = useTranslation() // Initialize the useTranslation hook

    return (
        <span
            className={`ml-auto text-gray-400 mx-2 tooltip ${
                index === 1 && 'tooltip-top'
            } font-medium`}
            data-tip={t(`Ο ρευματοδότης ενημερώθηκε ${lastUpdate}`)}
        >
            {updatedLongAgo ? (
                <div className="indicator">
                    <span className="indicator-item">
                        <FontAwesomeIcon
                            className="text-orange-500 ml-2"
                            icon={faExclamationCircle}
                        />
                    </span>
                    <div className="">
                        <FontAwesomeIcon icon={faRotate} className="mr-2" />
                        {formatEditedBefore(last_updated)}
                    </div>
                </div>
            ) : (
                <div>
                    <FontAwesomeIcon icon={faRotate} className="mr-2" />
                    {formatEditedBefore(last_updated)}
                </div>
            )}
        </span>
    )
}

interface Props {
    connector: Connector
    index: number
}
const ConnectorDetails: React.FC<Props> = ({ connector, index }) => {
    const { t } = useTranslation() // Initialize the useTranslation hook

    return (
        <div className="flex flex-col h-full min-h-max">
            <div className="flex flex-row items-center w-full mt-2  pb-1">
                <div className=" flex rounded-full border border-secondary text-secondary w-5 h-5">
                    <div className="text-center content-center font-bold m-auto">
                        {index}
                    </div>
                </div>
                <h3 className="ml-2 text-secondary font-bold">
                    {connector.standard}
                </h3>

                <p className="ml-auto ">
                    {t(connector.format)} / {t(connector.power_type)}
                </p>

                {connector.tariffs &&
                connector.tariffs.length > 0 &&
                connector.tariffs[0].elements.length > 0 &&
                connector.tariffs[0].elements[0].price_components ? (
                    <p className="badge badge-xs badge-secondary px-2 py-2 font-bold ml-auto">
                        <FontAwesomeIcon icon={faEuroSign} className="mr-2" />
                        {
                            connector.tariffs[0].elements[0].price_components[0]
                                .price
                        }
                        &nbsp;
                        {connector.tariffs[0].currency}
                        {connector.tariffs[0].elements[0].price_components[0]
                            .type === 'ENERGY' ? (
                            <span>&nbsp; / kWh</span>
                        ) : (
                            <span>&nbsp; / min</span>
                        )}
                    </p>
                ) : null}
            </div>

            <div className="p-1 flex">
                <div className="flex flex-col w-full">
                    <p>
                        {t('Μέγιστη Τάση')}:
                        <span className="text-blue-500 font-bold">
                            {' '}
                            {connector.max_voltage} V
                        </span>
                    </p>
                    <p>
                        {t('Μέγιστη Ένταση')}:
                        <span className="text-blue-500 font-bold">
                            {' '}
                            {connector.max_amperage} A
                        </span>
                    </p>
                    <p>
                        {t('Μέγιστη Ισχύς')}:
                        <span className="text-blue-500 font-bold">
                            {' '}
                            {connector.max_electric_power / 1000} kW
                        </span>
                    </p>
                </div>
                <div className="flex flex-col ml-auto">
                    <ConnectorIcon
                        connector_standard={connector.standard}
                        size={60}
                    />
                </div>
            </div>

            <div className="mb-3 ">
                <ConnectorCostComponent connector={connector} />
            </div>

            <div className="flex flex-row text-xs mt-auto">
                <UpdatedInfoComponent
                    index={index}
                    last_updated={connector.last_updated}
                />
            </div>
        </div>
    )
}

export default ConnectorDetails
