import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tariff, TariffElement } from '@/modules/ocpi/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt, faClock, faReceipt } from '@fortawesome/free-solid-svg-icons'

import { units, currency_label } from '@/modules/ocpi/lib/display-values'
import { PriceComponent, TariffDimensionType } from '@/modules/ocpi/types'

const tariffTypeLabels = {
    ENERGY: 'Ενέργεια',
    PARKING_TIME: 'Parking',
    FLAT: 'Πάγιο',
    TIME: 'Χρονοχρέωση',
}
const chargeIcon = (chargeType: TariffDimensionType) => {
    switch (chargeType) {
        case 'TIME':
            return <FontAwesomeIcon icon={faClock} className="mr-1" />
        case 'ENERGY':
            return <FontAwesomeIcon icon={faBolt} className="mr-1" />
        case 'PARKING_TIME':
            return <FontAwesomeIcon icon={faClock} className="mr-1" />
        case 'FLAT':
            return <FontAwesomeIcon icon={faReceipt} className="mr-1" />
        default:
            return null
    }
}

const ChargingStep = ({
    price_component,
}: {
    price_component: PriceComponent
}) => {
    const { t } = useTranslation()

    if (
        !price_component.step_size ||
        price_component.step_size === 1 ||
        price_component.step_size === 1000
    )
        return null
    // We have to account for inconsistent use of Wh and kWh
    // If step size/1000 > 1 then return /{step_size/1000}kWh
    if (
        price_component.step_size / 1000 > 1 &&
        price_component.type === 'ENERGY'
    ) {
        return (
            <span className="text-gray-300 font-bold">
                *{t('Χρέωση ανά')} {price_component.step_size / 1000}{' '}
                {t(units(price_component))}
            </span>
        )
    }

    return (
        <span
            className="text-gray-600 font-bold tooltip tooltip-top tooltip-narrow"
            data-tip={t(
                `Η κατανάλωση σας χρεώνεται ανά ${
                    price_component.step_size
                } ${t(units(price_component))}`
            )}
        >
            *{t('Χρέωση ανά')} {price_component.step_size}{' '}
            {t(units(price_component))}
        </span>
    )
}

interface TariffElementProps {
    index?: number
    element: TariffElement
    tariff: Tariff
}
const TariffElementComponent: React.FC<TariffElementProps> = ({
    element,
    tariff,
}) => {
    const { t } = useTranslation()
    const { price_components } = element
    const currencyLabel = currency_label(tariff)

    return (
        <table className="table-auto w-full table-zebra">
            {price_components.map((price_component, idx) => (
                <tr key={idx} className="border-t border-gray-300">
                    <td className="p-2">
                        <h4 className="text-slate-400 font-medium">
                            {chargeIcon(price_component.type)}
                            {t(tariffTypeLabels[price_component.type])}{' '}
                        </h4>
                    </td>
                    <td className="p-2">
                        <span className="text-blue-500 font-bold">
                            {price_component.price} {currencyLabel}/
                            {t(units(price_component))}
                        </span>
                    </td>
                    <td>
                        <span className="text-gray-500 ">
                            {price_component.vat
                                ? `+ ${t('vat')} ${price_component.vat}%`
                                : null}
                        </span>
                    </td>
                    <td className="p-2">
                        <ChargingStep price_component={price_component} />
                    </td>
                </tr>
            ))}
        </table>
    )
}

interface TariffComponentProps {
    index?: number
    tariff: Tariff
}

const TariffComponent: React.FC<TariffComponentProps> = ({ tariff, index }) => {
    const { elements } = tariff
    return (
        <div key={index}>
            <div className="mt-2 border-gray-300 py-1">
                {elements
                    ? elements.map((element, idx) => (
                          <>
                              <TariffElementComponent
                                  key={idx}
                                  tariff={tariff}
                                  element={element}
                              />
                          </>
                      ))
                    : null}
            </div>
        </div>
    )
}

export { TariffComponent, TariffElementComponent }
