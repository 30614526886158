import { PriceComponent, Tariff, TariffElement } from '@/modules/ocpi/types'

export const units = (price_component: PriceComponent) => {
    switch (price_component.type) {
        case 'ENERGY':
            return 'kWh'
        case 'TIME':
            // We have to account for inconsistent use of /min or /h
            // Normal cost per minute is around 0.05 so if price < 0.5 then return /min
            if (parseFloat(price_component.price) < 0.5) return 'min'
            return 'hour'
        case 'PARKING_TIME':
            return 'hour'
        default:
            return ''
    }
}

export const currency_label = (tariff: Tariff) => {
    return tariff.currency === 'EUR' ? '€' : tariff.currency || '€'
}
