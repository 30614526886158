import React from 'react'
import { useTranslation } from 'react-i18next'

import RaaeyLogoGr from '@/assets/img/raaey_logo_gr.webp'
import RaaeyLogoEn from '@/assets/img/raaey_logo_en.webp'

function RaaeyLogo({ className, ...rest }) {
    const { i18n } = useTranslation()

    const RaeLogo = i18n.language === 'el' ? RaaeyLogoGr : RaaeyLogoEn

    return <img src={RaeLogo} alt="RAAEY Logo" className={className} />
}

export { RaaeyLogo }
