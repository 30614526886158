import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'

import NavbarComponent from './nav/NavbarComponent'
import SidebarToggle from '@/components/sidebar/SidebarToggle'

import TopLoader from '@/components/TopLoader'

const Sidebar = React.lazy(() => import('@/components/sidebar/Sidebar'))

import { isMobile } from '@/lib/utils'

function PageLayout() {
    const [sidebarOpen, setSidebarOpen] = useState(true)

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen)
    }

    return (
        <div className="flex h-screen bg-gray-100">
            {/* Content Section */}

            {/* <TopLoader /> */}

            {/* Navbar */}
            <NavbarComponent>
                <div className={`pl-1  ${sidebarOpen ? 'ml-60' : 'ml-16'} `}>
                    <SidebarToggle
                        toggleSidebar={toggleSidebar}
                        isExpanded={sidebarOpen}
                    />
                </div>
            </NavbarComponent>

            {/* Sidebar */}
            {!isMobile() ? (
                <React.Suspense
                    fallback={
                        <div className="h-full w-60 skeletopn bg-primary" />
                    }
                >
                    <Sidebar sidebarOpen={sidebarOpen} />
                </React.Suspense>
            ) : null}

            {/* Main content */}
            <div className="w-full h-full overflow-y-auto">
                <Outlet />
            </div>
        </div>
    )
}

export default PageLayout
