import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

function AboutPage() {
    const { t } = useTranslation()

    return (
        <div className="min-h-screen pb-[64px] md:pt-[64px] md:pb-10 flex flex-col justify-center mx-4">
            <main className="h-full flex flex-col justify-center gap-10 ">
                <section className="lg:max-w-7xl w-full  py-4">
                    <div className="container px-3 md:pl-6 prose mx-auto text-black text-lg">
                        <Link to="/" className="link text-blue-800" link>
                            <FontAwesomeIcon
                                icon={faChevronLeft}
                                className="mr-2"
                            />
                            {t('Πίσω')}
                        </Link>
                        <h2 className="text-4xl font-bold mb-4">
                            {t('about_page.title')}
                        </h2>

                        <p className="my-2">{t(`about_page.p1`)}</p>
                        <p className="my-2">{t(`about_page.p2`)}</p>
                        <p className="my-2">{t(`about_page.p3`)}</p>
                        <p className="my-2">{t(`about_page.p4`)}</p>
                        <p className="my-2">{t(`about_page.p5`)}</p>

                        <div className="border-t border-secondary my-6" />

                        <p className="my-2">
                            {t('about_page.alpha_contents_label')}
                            <ul className="list-disc">
                                <li>{t('about_page.alpha_contents_1')}</li>
                                <li>{t('about_page.alpha_contents_2')}</li>
                                <li>{t('about_page.alpha_contents_3')}</li>
                                <li>{t('about_page.alpha_contents_4')}</li>
                            </ul>
                        </p>

                        <div className="border-t border-secondary my-6" />

                        <p className="my-2">
                            {t('about_page.beta_contents_label')}
                            <ul className="list-decimal">
                                <li>
                                    <span className="font-bold">
                                        {t('about_page.beta_contents_1.title')}
                                    </span>
                                    <ul className="list-disc">
                                        <li>
                                            {t('about_page.beta_contents_1.1')}
                                        </li>
                                        <li>
                                            {t('about_page.beta_contents_1.2')}
                                        </li>
                                        <li>
                                            {t('about_page.beta_contents_1.3')}
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <span className="font-bold">
                                        {t('about_page.beta_contents_2.title')}
                                    </span>
                                    <ul className="list-disc">
                                        <li>
                                            {t('about_page.beta_contents_2.1')}
                                        </li>
                                        <li>
                                            {t('about_page.beta_contents_2.2')}
                                        </li>
                                        <li>
                                            {t('about_page.beta_contents_2.3')}
                                        </li>
                                        <li>
                                            {t('about_page.beta_contents_2.4')}
                                        </li>
                                        <li>
                                            {t('about_page.beta_contents_2.5')}
                                        </li>
                                        <li>
                                            {t('about_page.beta_contents_2.6')}
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <span className="font-bold">
                                        {t('about_page.beta_contents_3.title')}
                                    </span>
                                    <ul className="list-disc">
                                        <li>
                                            {t('about_page.beta_contents_3.1')}
                                        </li>
                                        <li>
                                            {t('about_page.beta_contents_3.2')}
                                        </li>
                                        <li>
                                            {t('about_page.beta_contents_3.3')}
                                        </li>
                                        <li>
                                            {t('about_page.beta_contents_3.4')}
                                        </li>
                                        <li>
                                            {t('about_page.beta_contents_3.5')}
                                        </li>
                                        <li>
                                            {t('about_page.beta_contents_3.6')}
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <span className="font-bold">
                                        {t('about_page.beta_contents_4.title')}
                                    </span>
                                    <ul className="list-disc">
                                        <li>
                                            {t('about_page.beta_contents_4.1')}
                                        </li>
                                        <li>
                                            {t('about_page.beta_contents_4.2')}
                                        </li>
                                        <li>
                                            {t('about_page.beta_contents_4.3')}
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </p>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default AboutPage
