import { useState, useMemo, useEffect } from 'react'
import type { LocationPoints } from '@/api'
import { SortingOption } from './main'

// Create a new worker instance
const worker = new Worker(new URL('./sort-worker.ts', import.meta.url), {
    type: 'module',
})

export function useSortedData(
    data: LocationPoints[],
    sortingOption: SortingOption
) {
    const [sortedData, setSortedData] = useState<LocationPoints[]>([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<boolean>(false)

    useEffect(() => {
        if (!data || data.length === 0) return

        setLoading(true) // Set loading state while processing
        worker.postMessage({ data, sortingOption }) // Send data to the worker

        // Handle response from the worker
        worker.onmessage = (event) => {
            setSortedData(event.data)
            setLoading(false)
        }

        // Handle any errors from the worker
        worker.onerror = (error) => {
            console.error('Worker error:', error)
            setLoading(false)
            setError(true)
        }
    }, [data, sortingOption]) // Re-run only if data or sortingOption changes

    // Memoize the output object to prevent re-renders
    return useMemo(
        () => ({ sortedData, loading, error }),
        [sortedData, loading, error]
    )
}
