import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCalendarDays,
    faClock,
    faCheck,
    faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'
import { OpeningTimes, RegularHour } from '@/modules/ocpi/types'
import {
    getDayName,
    getDay,
    checkIsOpen,
    checkIsClosingSoon,
} from '@/modules/ocpi/regular_hour/utils'

interface OpeningTimesComponentProps {
    opening_times: OpeningTimes[]
    charging_when_closed?: boolean
    defaultExpanded?: boolean
    badgeOnly?: boolean
}

const OpeningTimesComponent: React.FC<OpeningTimesComponentProps> = ({
    opening_times,
    charging_when_closed,
    badgeOnly = false,
}) => {
    const { t } = useTranslation() // Initialize the translation function
    if (!opening_times) return null
    if (opening_times.length === 0) return null
    const {
        twentyfourseven,
        exceptional_openings,
        exceptional_closings,
        regular_hours,
    } = opening_times[0]

    const isOpen = checkIsOpen(opening_times)
    const isClosingSoon = checkIsClosingSoon(opening_times)

    const OpenIndicator = () => {
        if (isClosingSoon) {
            return (
                <div className="flex flex-row items-center">
                    <span className="bg-yellow-500 h-2 w-2 mask mask-circle mr-2"></span>
                    <span>
                        {t('Κλείνει σύντομα')}{' '}
                        <span className="font-medium text-gray-600">
                            ({isClosingSoon} {t('λεπτά')})
                        </span>
                    </span>
                </div>
            )
        }
        if (isOpen) {
            return (
                <div className="flex flex-row items-center">
                    <span className="bg-green-600 h-2 w-2 mask mask-circle mr-2"></span>
                    <span>{t('Ανοιχτό')} </span>
                </div>
            )
        }
        return (
            <div className="flex flex-row items-center gap-2">
                <span className="bg-red-600 h-2 w-2 mask mask-circle mr-2"></span>
                <span>{t('Κλειστό')} </span>

                <ChargingWhenClosedIndicator />
                {/* 
                {!charging_when_closed ? (
                    <span className="text-xs ml-2 text-gray-400">
                        {t('Φόρτιση εκτός ωραρίου')}
                    </span>
                ) : null} */}
            </div>
        )
    }

    const ChargingWhenClosedIndicator = () => {
        if (charging_when_closed) {
            return (
                <div className="text-xs ml-auto text-gray-500">
                    {t('Φόρτιση εκτός ωραρίου')}
                    <FontAwesomeIcon
                        icon={faCheck}
                        className=" inline-block ml-1"
                    />
                </div>
            )
        }
        return null
    }

    const RegularHoursComponent = () => {
        // console.log(regular_hours)
        return (
            <div className="space-y-4 ">
                <OpenIndicator />
                {charging_when_closed ? (
                    <div className="flex gap-2 items-center p-2 bg-gray-300 rounded-lg">
                        <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                        {t(
                            'Ο σταθμός επιτρέπει την φόρτιση εκτός του ωραρίου λειτουργίας'
                        )}
                    </div>
                ) : null}

                <hr />
                <h3>
                    <FontAwesomeIcon icon={faCalendarDays} className="mr-2" />
                    {t('Ωράριο Λειτουργίας')}
                </h3>
                <table className="w-full m-2">
                    <tbody>
                        {regular_hours
                            .sort((a, b) => a.weekday - b.weekday)
                            .map(
                                (
                                    itinerary_item: RegularHour,
                                    index: number
                                ) => (
                                    <tr
                                        key={index}
                                        className={
                                            getDay() === itinerary_item.weekday
                                                ? 'bg-blue-100 '
                                                : ''
                                        }
                                    >
                                        <th className="flex flex-row items-center content-center ">
                                            <FontAwesomeIcon
                                                icon={faClock}
                                                className="mr-2"
                                            />
                                            {getDayName(itinerary_item.weekday)}
                                            :{' '}
                                        </th>
                                        <td className="text-blue-500 font-medium">
                                            {itinerary_item.period_begin} -{' '}
                                            {itinerary_item.period_end}
                                        </td>
                                        {getDay() === itinerary_item.weekday ? (
                                            <td className=" text-blue-300 font-bold">
                                                {t('Σήμερα')}
                                            </td>
                                        ) : null}
                                    </tr>
                                )
                            )}
                    </tbody>
                </table>
            </div>
        )
    }

    const TwentyfourSeven = () => {
        return (
            <div className="flex flex-row items-center  w-full">
                <OpenIndicator />

                <div className="flex flex-row items-center content-center ml-auto ">
                    <FontAwesomeIcon icon={faClock} className="mr-2" />
                    <span className="font-bold">{t('Λειτουργεί 24/7')}</span>
                </div>
            </div>
        )
    }

    if (badgeOnly) return <OpenIndicator />

    return (
        <>
            {twentyfourseven ? <TwentyfourSeven /> : null}
            {regular_hours ? <RegularHoursComponent /> : null}
        </>
    )
}

export { OpeningTimesComponent }
